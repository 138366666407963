import { Html } from '@react-three/drei'
import gsap from 'gsap/all'
import React, { ReactElement, useEffect } from 'react'
import styled from 'styled-components/macro'
import useBreakpoint from '../../hooks/useBreakpoint'
import { Device, ScrollDirection } from '../../interfaces/three.d'
import { delay } from './helper'
import { ModelProvider } from './ModelProvider'

interface FullPageTransitionProps {
  isActive: boolean
  duration: number
  models: ModelProvider | null
  scrollDirection: ScrollDirection
  children: React.ReactNode
  currentMediaQuery: Device
}
/**
 * Renders an HTML overlay inside a canvas element.
 */
export default function FullPageTransition({
  isActive,
  duration,
  models,
  scrollDirection,
  children,
  currentMediaQuery,
}: FullPageTransitionProps): ReactElement {
  const isDesktop = useBreakpoint('lg')
  // Play custom transition animations
  useEffect(() => {
    // early out
    if (!isActive) return

    if (scrollDirection === 'down') {
      if (!models?.refList) return
      if (
        models.refList.planetScene.current &&
        currentMediaQuery !== Device.Mobile
      ) {
        gsap.to(models.refList.planetScene.current.scale, {
          duration: 1,
          x: 2,
          y: 2,
          z: 2,
        })
      }

      delay(1).then(() => {
        if (!models?.refList) return
        if (models.refList.rocketAssemblyScene.current) {
          gsap.fromTo(
            models.refList.rocketAssemblyScene.current.scale,
            {
              x: 0,
              y: 0,
              z: 0,
            },
            {
              duration: 2,
              x: 2,
              y: 2,
              z: 2,
            }
          )
        }
      })

      delay(1.1).then(() => {
        if (!models?.refList) return
        models.refList.planetScene.current?.scale.set(0, 0, 0)
      })

      // backup planet removal
      delay(3).then(() => {
        if (!models?.refList) return
        /*        if (models.refList.planetScene.current?.scale.x !== 0) {
          console.warn(
            'backup resizing triggered',
            models.refList.planetScene.current?.scale
          )
        } */
        models.refList.planetScene.current?.scale.set(0, 0, 0)
      })

      delay(5.5).then(() => {
        if (!models?.refList) return
        // backup set scale to 0
        models.refList.planetScene.current?.scale.set(0, 0, 0)
        if (!models.refList.rocket.current) return

        models.refList.plainRocket.current?.scale.set(0, 0, 0)
        models.refList.rocket.current.visible = true
      })
    } else {
      if (!models?.refList) return
      if (models.refList.rocketAssemblyScene.current) {
        delay(1.01).then(() => {
          if (!models?.refList) return
          if (!models.refList.rocket.current) return
          models.refList.rocketAssemblyScene.current?.scale.set(0, 0, 0)
          models.refList.plainRocket.current?.scale.set(1, 1, 1)
          models.refList.rocket.current.visible = false

          isDesktop
            ? models.refList.planetScene.current?.scale.set(1.6, 1.6, 1.6)
            : models.refList.planetScene.current?.scale.set(1.1, 1.1, 1.1)
        })

        // backup scale reset
        delay(2).then(() => {
          if (!models?.refList) return
          if (!models.refList.rocket.current) return
          models.refList.rocketAssemblyScene.current?.scale.set(0, 0, 0)
          models.refList.plainRocket.current?.scale.set(1, 1, 1)
          models.refList.rocket.current.visible = false

          isDesktop
            ? models.refList.planetScene.current?.scale.set(1.6, 1.6, 1.6)
            : models.refList.planetScene.current?.scale.set(1.1, 1.1, 1.1)
        })
      }
    }
  }, [isActive])

  return (
    <>
      <Html>
        <StyledBackground isActive={isActive} duration={duration}>
          {/* Put content here that should show during the white screen */}
        </StyledBackground>
      </Html>
      {children}
    </>
  )
}

const StyledBackground = styled.div<{ isActive: boolean; duration: number }>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity ${({ duration }) => duration}s ease-in;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  z-index: 999;
  pointer-events: none;
`
