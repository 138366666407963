import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'
import { GroupProps } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import { DreiGLTF, HumanActions } from '../../../interfaces/three'
import { addSubclipAnimations } from '../helper'

interface HumanProps extends GroupProps {
  humanActions: HumanActions
  humanRef: React.MutableRefObject<THREE.Group | null>
}

export default function Human({
  humanActions,
  humanRef,
  ...props
}: HumanProps) {
  const humanScene = useRef<THREE.Group>()
  const { nodes, materials, animations } = useGLTF(
    '/models/glb/Human.glb'
  ) as DreiGLTF

  // Create subclips
  const subclipBase = animations.find(
    animation => animation.name === 'Animation'
  )

  if (subclipBase) {
    const reveal: THREE.AnimationClip = THREE.AnimationUtils.subclip(
      subclipBase,
      'reveal',
      0,
      40,
      24
    )
    const idle: THREE.AnimationClip = THREE.AnimationUtils.subclip(
      subclipBase,
      'idle',
      40,
      110,
      24
    )
    const idleAndWave: THREE.AnimationClip = THREE.AnimationUtils.subclip(
      subclipBase,
      'idleAndWave',
      40,
      140,
      24
    )
    const disappear: THREE.AnimationClip = THREE.AnimationUtils.subclip(
      subclipBase,
      'disappear',
      140,
      170,
      24
    )

    addSubclipAnimations(reveal, animations)
    addSubclipAnimations(idle, animations)
    addSubclipAnimations(idleAndWave, animations)
    addSubclipAnimations(disappear, animations)
  }

  const { actions } = useAnimations<THREE.AnimationClip>(animations, humanScene)

  useEffect(() => {
    humanActions.reveal = actions.reveal
    humanActions.idle = actions.idle
    humanActions.idleAndWave = actions.idleAndWave
  }, [])

  return (
    <group ref={humanRef} {...props} dispose={null}>
      {/* This ref is used to fetch the animations */}
      <group ref={humanScene}>
        <group name="Armature-BigHuman" position={[0, -1.4, 0]}>
          <primitive object={nodes.BodyCenter} />
          <primitive object={nodes.Root} />
          <skinnedMesh
            name="Human"
            geometry={nodes.Human.geometry}
            material={materials.M_Human}
            skeleton={nodes.Human.skeleton}
          />
        </group>
      </group>
    </group>
  )
}
