import {
  Sphere,
  useAnimations,
  useGLTF,
  shaderMaterial,
} from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'
import { GroupProps, Vector3 } from '@react-three/fiber'
import React, { useEffect, useState } from 'react'
import * as THREE from 'three'
import {
  DreiGLTF,
  SubclipBase,
  AllPlanetRefs,
  PlanetActions,
  HumanActions,
  Device,
} from '../../../interfaces/three.d'

import { createSubclips } from '../helper'
import Human from './Human'

import { extend } from '@react-three/fiber'
import useBreakpoint from '../../../hooks/useBreakpoint'

interface PlanetSceneNewProps extends GroupProps {
  allPlanetRefs: AllPlanetRefs
  planetActions: PlanetActions
  humanActions: HumanActions
  currentAnimation: number
  handleOnButtonClick(): void
  currentMediaQuery: Device
}
export default function PlanetScene({
  handleOnButtonClick,
  allPlanetRefs,
  planetActions,
  humanActions,
  currentAnimation,
  currentMediaQuery,
  ...props
}: PlanetSceneNewProps) {
  const { planetScene, planet, planetLeft, planetRight, human } = allPlanetRefs
  const isDesktop = useBreakpoint('sm')
  const humanScale = isDesktop
    ? new THREE.Vector3(1, 1, 1)
    : new THREE.Vector3(1.15, 1.15, 1.15)
  const isButtonClickable = currentAnimation === 1

  const [hovered, setHovered] = useState(false)

  useEffect(() => {
    if (hovered && isButtonClickable) {
      document.body.style.cursor = 'pointer'
    } else {
      document.body.style.cursor = 'auto'
    }
  }, [hovered])

  const { nodes, materials, animations } = useGLTF(
    '/models/glb/PlanetScene.glb'
  ) as DreiGLTF

  // Create subclips
  const clipBasePlanetLeft = animations.find(
    (animation: THREE.AnimationClip) => animation.name === 'Planet_Animations_L'
  )
  const clipBasePlanetRight = animations.find(
    (animation: THREE.AnimationClip) => animation.name === 'Planet_Animations_R'
  )

  const subclipBase: SubclipBase[] = [
    { clip: clipBasePlanetLeft, name: 'openPlanetAction_L' },
    { clip: clipBasePlanetRight, name: 'openPlanetAction_R' },
  ]

  createSubclips(subclipBase, animations, 20, 90, 24)

  const { actions } = useAnimations<THREE.AnimationClip>(
    animations,
    planetScene
  )

  useEffect(() => {
    planetActions.openPlanetAction_L = actions.openPlanetAction_L
    planetActions.openPlanetAction_R = actions.openPlanetAction_R
    planetActions.Armature_FerrisWheelAction =
      actions.Armature_FerrisWheelAction
    planetActions['Action.001'] = actions['Action.001']
    planetActions.Armature_BaloonAction = actions.Armature_BaloonAction
    planetActions['Armature_WindTurbine_01Action.001'] =
      actions['Armature_WindTurbine_01Action.001']
    planetActions.Armature_TelescopeAction = actions.Armature_TelescopeAction
    planetActions.Action = actions.Action
    planetActions.Armature_Clouds_LAction = actions.Armature_Clouds_LAction
    planetActions.Armature_Clouds_RAction = actions.Armature_Clouds_RAction
    planetActions['Armature_ArrowAction.001'] =
      actions['Armature_ArrowAction.001']
  }, [])

  const onButtonClick = () => {
    if (actions['Armature_ButtonAction.002'] && isButtonClickable) {
      actions['Armature_ButtonAction.002'].reset()
      actions['Armature_ButtonAction.002'].loop = THREE.LoopOnce
      actions['Armature_ButtonAction.002'].clampWhenFinished = true
      actions['Armature_ButtonAction.002']?.play()
    }
    handleOnButtonClick()
  }

  const Atmosphere = shaderMaterial(
    {
      blending: THREE.AdditiveBlending,
      side: THREE.BackSide,
    },
    // vertex shader
    glsl`
    varying vec3 vertexNormal;
    void main() {
      vertexNormal = normal;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
    // fragment shader
    glsl`
    varying vec3 vertexNormal;
    void main() {
      float intensity = pow(0.08 - dot(vertexNormal, vec3(0.0, 0.0, 1.0)), 2.0);
      gl_FragColor = vec4(0.89, 0.9, 0.75, 1.0) * intensity;
    }
  `
  )

  extend({ Atmosphere })

  const isMobile = currentMediaQuery === Device.Mobile
  const ambientScale: Vector3 = isMobile
    ? [30.0, 30.0, 30.0]
    : [45.0, 45.0, 45.0]

  return (
    <group ref={planetScene} dispose={null} position={[0, -45, -20]} {...props}>
      <Sphere scale={ambientScale}>
        <atmosphere attach="material" />
      </Sphere>

      <group ref={planet} rotation={[-0.28, 0.2, -0.5]}>
        <group ref={planetLeft} name="planetLeft">
          <primitive object={nodes.Root_1} />

          <skinnedMesh
            name="PlanetSurface_L"
            frustumCulled={false}
            geometry={nodes.PlanetSurface_L.geometry}
            material={nodes.PlanetSurface_L.material}
            skeleton={nodes.PlanetSurface_L.skeleton}
          />
          <skinnedMesh
            name="Roads_L"
            frustumCulled={false}
            geometry={nodes.Roads_L.geometry}
            material={nodes.Roads_L.material}
            skeleton={nodes.Roads_L.skeleton}
          />
          <skinnedMesh
            name="Rocket_Holder_L"
            frustumCulled={false}
            geometry={nodes.Rocket_Holder_L.geometry}
            material={materials.M_MechanicalArm}
            skeleton={nodes.Rocket_Holder_L.skeleton}
          />
          <skinnedMesh
            name="Rocket_L"
            frustumCulled={false}
            geometry={nodes.Rocket_L.geometry}
            material={materials.M_Rocket}
            skeleton={nodes.Rocket_L.skeleton}
          />
          <skinnedMesh
            name="RocketWindows_L"
            frustumCulled={false}
            geometry={nodes.RocketWindows_L.geometry}
            material={materials.M_RocketWindow}
            skeleton={nodes.RocketWindows_L.skeleton}
          />
          <skinnedMesh
            name="StaticBuildings_L"
            frustumCulled={false}
            geometry={nodes.StaticBuildings_L.geometry}
            material={nodes.StaticBuildings_L.material}
            skeleton={nodes.StaticBuildings_L.skeleton}
          />
          <skinnedMesh
            name="Trees_L"
            frustumCulled={false}
            geometry={nodes.Trees_L.geometry}
            material={nodes.Trees_L.material}
            skeleton={nodes.Trees_L.skeleton}
          />
        </group>

        <group ref={planetRight} name="planetRight">
          <skinnedMesh
            name="PlanetSurface_R"
            frustumCulled={false}
            geometry={nodes.PlanetSurface_R.geometry}
            material={nodes.PlanetSurface_R.material}
            skeleton={nodes.PlanetSurface_R.skeleton}
          />
          <skinnedMesh
            name="Button_1"
            frustumCulled={false}
            geometry={nodes.Button_1.geometry}
            material={nodes.Button_1.material}
            skeleton={nodes.Button_1.skeleton}
            onClick={onButtonClick}
            onPointerOver={() => setHovered(true)}
            onPointerOut={() => setHovered(false)}
          />
          <skinnedMesh
            name="Roads_R"
            frustumCulled={false}
            geometry={nodes.Roads_R.geometry}
            material={nodes.Roads_R.material}
            skeleton={nodes.Roads_R.skeleton}
          />
          <skinnedMesh
            name="StaticBuildings_R"
            frustumCulled={false}
            geometry={nodes.StaticBuildings_R.geometry}
            material={nodes.StaticBuildings_R.material}
            skeleton={nodes.StaticBuildings_R.skeleton}
          />
          <skinnedMesh
            name="Trees_R"
            frustumCulled={false}
            geometry={nodes.Trees_R.geometry}
            material={nodes.Trees_R.material}
            skeleton={nodes.Trees_R.skeleton}
          />
          <primitive object={nodes.Root_02} />
        </group>
      </group>

      <Human
        humanRef={human}
        humanActions={humanActions}
        position={[0, -2, 0]}
        scale={humanScale}
        rotation={[0, 0.3, 0]}
      />
    </group>
  )
}
