// TODO refactor arrow down button

import React from 'react'
import styled from 'styled-components/macro'

interface Props {
  color?: string
  isHomepage?: boolean
  isLeftAligned: boolean
  isCentered: boolean
  showArrow: boolean
  isFirstAnimation?: boolean
}

ArrowDown.defaultProps = {
  isFirstAnimation: false,
}

export default function ArrowDown({
  color,
  isHomepage,
  isLeftAligned,
  isCentered,
  showArrow,
  isFirstAnimation,
}: Props): JSX.Element {
  return (
    <StyledArrowDown
      role="svg"
      width="13"
      height="21"
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isHomepage={isHomepage}
      isLeftAligned={isLeftAligned}
      isCentered={isCentered}
      showArrow={showArrow}
      isFirstAnimation={isFirstAnimation}
    >
      <title>Discover More Arrow</title>
      <path d="M6.43359 0V18.794" stroke={color} strokeWidth="2" />
      <path
        d="M1 13.362L6.43311 18.795L11.866 13.362"
        stroke={color}
        strokeWidth="2"
      />
    </StyledArrowDown>
  )
}

const StyledArrowDown = styled.svg<{
  isHomepage?: boolean
  isLeftAligned: boolean
  isCentered: boolean
  showArrow: boolean
  isFirstAnimation?: boolean
}>`
  display: ${({ isHomepage }) => (isHomepage ? 'none' : 'block')};
  margin: ${({ isHomepage, isCentered }) =>
    isHomepage && isCentered ? '1rem auto' : '0 auto'};
  position: ${({ isHomepage, isCentered }) =>
    isHomepage && !isCentered ? 'absolute' : 'static'};
  bottom: 2rem;
  left: ${({ isLeftAligned }) => (isLeftAligned ? '100%' : '0')};
  right: ${({ isLeftAligned, isCentered }) =>
    isLeftAligned || isCentered ? '0' : '100%'};
  animation-duration: ${({ isHomepage }) => (isHomepage ? '1s' : '0.6s')};
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-iteration-count: ${({ isHomepage }) =>
    isHomepage ? 'infinite' : ''};
  opacity: ${({ showArrow }) => (showArrow ? '1' : '0')};
  transition: opacity 0.3s ease-in;
  animation-name: ${({ isHomepage }) =>
    isHomepage ? 'Arrow-Down-Animation' : 'none'};

  ${({ theme }) => theme.breakpoints.queries.sm} {
    display: ${({ isHomepage, isFirstAnimation }) =>
      isHomepage && isFirstAnimation ? 'none' : 'block'};
    &:hover {
      animation-name: ${({ isHomepage }) =>
        isHomepage ? 'none' : 'Arrow-Down-Animation'};
    }
  }
`
