import { useEffect, useState } from 'react'
import { ScrollDirection } from '../interfaces/three.d'
import { throttle, debounce } from 'throttle-debounce'

export const SCROLL_UP = ScrollDirection.Up
export const SCROLL_DOWN = ScrollDirection.Down

/**
 * It returns a string that represents the direction of the scroll
 * @param initialDirection - The initial direction of the scroll.
 * @param [thresholdPixels=10] - This is the number of pixels that the user has to scroll before the
 * event is fired.
 * @returns A function that returns a scroll direction
 */
const useScrollDirection = (
  initialDirection = SCROLL_DOWN,
  //Threshold decides the frequency to fire the event. A lower threshold results in less space needed to get scrolled to provide the correct fullPageTransition
  thresholdPixels = 10
) => {
  const [scrollDir, setScrollDir] = useState<ScrollDirection>(initialDirection)

  useEffect(() => {
    const threshold: number = thresholdPixels || 0
    let lastScrollY: number = window.scrollY

    const updateScrollDir = () => {
      const scrollY: number = window.scrollY

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        return
      }

      setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP)
      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    const onScroll = () => {
      updateScrollDir()
    }

    window.addEventListener('scroll', debounce(10, onScroll))

    return () => window.removeEventListener('scroll', onScroll)
  }, [initialDirection, thresholdPixels, scrollDir])

  return scrollDir
}

export default useScrollDirection
