import React, { useRef, useEffect } from 'react'
import * as THREE from 'three'
import { GroupProps, useFrame } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'
import gsap from 'gsap'
import {
  DreiGLTF,
  AllRocketRefs,
  RocketActions,
} from '../../../interfaces/three.d'
import { addSubclipAnimations } from '../helper'

// type ActionName = 'Armature_StarsAction.001' | 'ArmatureAction'
interface RocketProps extends GroupProps {
  allRefs?: AllRocketRefs
  rocketActions?: RocketActions
  starsVisible?: boolean
  drivePosition?: THREE.Vector3
  rocketProps?: {
    position?: THREE.Vector3
    rotation?: THREE.Euler
    scale?: THREE.Vector3
  }
  starsProps?: {
    position?: THREE.Vector3
    rotation?: THREE.Euler
    scale?: THREE.Vector3
  }
  isMoving?: boolean
  movingStrength?: number
}

export default function RocketInSpace({
  allRefs,
  rocketActions,
  starsVisible = true,
  rocketProps,
  starsProps,
  isMoving = true,
  movingStrength = 1,
  drivePosition,
  ...props
}: RocketProps) {
  const rocketScene = useRef<THREE.Group>(null)
  const rocket = useRef<THREE.Group>(null)

  const { nodes, materials, animations } = useGLTF(
    '/models/glb/SpaceRocket.glb'
  ) as DreiGLTF

  const subclipBase = animations.find(
    animation => animation.name === 'ArmatureAction'
  )
  if (subclipBase) {
    const rocketIdle: THREE.AnimationClip = THREE.AnimationUtils.subclip(
      subclipBase,
      'rocketIdle',
      0,
      27,
      24
    )

    const rocketStart: THREE.AnimationClip = THREE.AnimationUtils.subclip(
      subclipBase,
      'rocketStart',
      27,
      60,
      24
    )

    addSubclipAnimations(rocketIdle, animations)
    addSubclipAnimations(rocketStart, animations)
  }

  const { actions } = useAnimations<THREE.AnimationClip>(
    animations,
    rocketScene
  )

  useEffect(() => {
    if (!rocketActions) return
    rocketActions.rocketIdle = actions.rocketIdle
    rocketActions.rocketStart = actions.rocketStart
    rocketActions['Armature_StarsAction.001'] =
      actions['Armature_StarsAction.001']
  }, [])

  useFrame(({ clock }) => {
    if (isMoving) {
      if (rocket.current) {
        rocket.current.position.x = -(
          Math.sin(clock.getElapsedTime() * 1) *
          0.1 *
          movingStrength
        )
        rocket.current.position.y =
          Math.cos(clock.getElapsedTime() * 0.5) * 0.4 * movingStrength

        rocket.current.position.z =
          Math.cos(clock.getElapsedTime() * 1) * 0.1 * movingStrength
      }
      nodes.Cube.rotation.set(0, 0, Math.PI)
      nodes.Cube.scale.y = Math.sin(clock.getElapsedTime() * 1.2) * 0.1 + 1.3
      if (nodes.Cube.position != drivePosition) {
        if (!drivePosition) return
        nodes.Cube.position.x = drivePosition.x
        nodes.Cube.position.y = drivePosition.y
        nodes.Cube.position.z = drivePosition.z
      }
    } else {
      // Smooth return to original position
      if (!rocket.current) return
      if (rocket.current.position !== new THREE.Vector3(0, 0, 0)) {
        gsap.to(rocket.current.position, {
          x: 0,
          y: 0,
          z: 0,
          duration: 1,
        })
      }
    }
  })

  return (
    <group ref={allRefs?.rocket} {...props}>
      {/* This ref is used to fetch the animations */}
      <group ref={rocketScene}>
        {/* This group places the stars above the rocket (out of screen) */}
        <group name="starsGroup" ref={allRefs?.stars} visible={starsVisible}>
          <primitive
            object={nodes.Root}
            name="starsBone"
            position={[0, 50, 5]}
            {...starsProps}
          />
          <skinnedMesh
            name="starsMesh"
            frustumCulled={false}
            geometry={nodes.Stars.geometry}
            material={materials.Stars}
            skeleton={nodes.Stars.skeleton}
          />
        </group>
        <group name="rocketGroup" ref={rocket}>
          <primitive object={nodes.Root_1} name="Rocket" {...rocketProps} />
          <skinnedMesh
            name="rocketMesh"
            frustumCulled={false}
            geometry={nodes.Rocket_1.geometry}
            material={materials.M_Rocket}
            skeleton={nodes.Rocket_1.skeleton}
          />
          <skinnedMesh
            name="rocketWindowsMesh"
            frustumCulled={false}
            geometry={nodes.RocketWindows.geometry}
            material={materials.M_RocketWindow}
            skeleton={nodes.RocketWindows.skeleton}
          />
        </group>
      </group>
    </group>
  )
}
