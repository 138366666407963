import React, { useRef, useEffect, ReactElement } from 'react'
import * as THREE from 'three'
import { GroupProps } from '@react-three/fiber'
import {
  Sphere,
  GradientTexture,
  useAnimations,
  useGLTF,
  shaderMaterial,
} from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'
import {
  DreiGLTF,
  RocketAssemblyRefs,
  RocketAssemblyActions,
} from '../../../interfaces/three.d'

import { extend } from '@react-three/fiber'

interface RocketAssemblyProps extends GroupProps {
  allRefs: RocketAssemblyRefs
  rocketAssemblyActions: RocketAssemblyActions
}

export default function RocketAssembly({
  rocketAssemblyActions,
  allRefs,
  children,
  ...props
}: RocketAssemblyProps): ReactElement {
  const rocketAssemblyScene = useRef<THREE.Group>()

  const { nodes, materials, animations } = useGLTF(
    '/models/glb/RocketAssembly.glb'
  ) as DreiGLTF

  const { actions } = useAnimations<THREE.AnimationClip>(
    animations,
    rocketAssemblyScene
  )

  const Atmosphere = shaderMaterial(
    { blending: THREE.AdditiveBlending, side: THREE.BackSide },
    // vertex shader
    glsl`
      varying vec3 vertexNormal;
      void main() {
        vertexNormal = normal;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
  `,
    // fragment shader
    glsl`
      varying vec3 vertexNormal;
      void main() {
        float intensity = pow(0.08 - dot(vertexNormal, vec3(0.0, 0.0, 1.0)), 2.0);
        gl_FragColor = vec4(0.89, 0.9, 0.75, 1.0) * intensity;
      }
  `
  )

  extend({ Atmosphere })

  useEffect(() => {
    rocketAssemblyActions.Armature_MechanicalArmAction =
      actions.Armature_MechanicalArmAction
    rocketAssemblyActions['Action.002'] = actions['Action.002']
    rocketAssemblyActions['Armature_Engineer_01.001Action'] =
      actions['Armature_Engineer_01.001Action']
    rocketAssemblyActions['Armature_Engineer_01.002Action'] =
      actions['Armature_Engineer_01.002Action']
    rocketAssemblyActions.Armature_Engineer_01Action =
      actions.Armature_Engineer_01Action
    rocketAssemblyActions.Armature_JoyPadAction = actions.Armature_JoyPadAction
    rocketAssemblyActions['Armature_ObservatoryAction.001'] =
      actions['Armature_ObservatoryAction.001']
    rocketAssemblyActions.Armature_SkyAction = actions.Armature_SkyAction
    rocketAssemblyActions.Armature_CarAction = actions.Armature_CarAction
  }, [])

  return (
    <group
      ref={allRefs.rocketAssemblyScene}
      {...props}
      position={[8, -30, 0]}
      scale={[0, 0, 0]}
      dispose={null}
    >
      {/* This ref is used to fetch the animations */}
      <group ref={rocketAssemblyScene}>
        <group ref={allRefs.rocketAssemblyPlanet}>
          <Sphere scale={[55.0, 35.0, 35.0]} position={[0, -5, 0]}>
            <atmosphere attach="material" />
          </Sphere>
          <primitive object={nodes.Root} />
          <skinnedMesh
            name="Clouds"
            frustumCulled={false}
            geometry={nodes.Clouds.geometry}
            material={materials.M_Clouds}
            skeleton={nodes.Clouds.skeleton}
          />
          <skinnedMesh
            name="Satellite_1"
            frustumCulled={false}
            geometry={nodes.Satellite_1.geometry}
            material={nodes.Satellite_1.material}
            skeleton={nodes.Satellite_1.skeleton}
          />
          <group name="Armature_Engineer_01" position={[6.4, 5.07, 2.97]}>
            <primitive object={nodes.BodyCenter} />
            <primitive object={nodes.Root_1} />
            <skinnedMesh
              name="Engineer_01"
              frustumCulled={false}
              geometry={nodes.Engineer_01.geometry}
              material={nodes.Engineer_01.material}
              skeleton={nodes.Engineer_01.skeleton}
            />
          </group>
          <group name="Armature_Engineer_03" position={[4.28, 13.6, 0.74]}>
            <primitive object={nodes.BodyCenter_1} />
            <primitive object={nodes.Root_2} />
            <skinnedMesh
              name="Engineer_01001"
              frustumCulled={false}
              geometry={nodes.Engineer_01001.geometry}
              material={nodes.Engineer_01001.material}
              skeleton={nodes.Engineer_01001.skeleton}
            />
          </group>
          <group name="Armature_Engineer_02" position={[3.5, 4.37, 7.99]}>
            <primitive object={nodes.BodyCenter_2} />
            <primitive object={nodes.Root_3} />
            <skinnedMesh
              name="Engineer_01002"
              frustumCulled={false}
              geometry={nodes.Engineer_01002.geometry}
              material={nodes.Engineer_01002.material}
              skeleton={nodes.Engineer_01002.skeleton}
            />
          </group>
          <group name="Armature_MechanicalArm" position={[-2.13, 6.48, 1.2]}>
            <primitive object={nodes.Base} />
            <skinnedMesh
              name="Rocket_Holder_Base"
              frustumCulled={false}
              geometry={nodes.Rocket_Holder_Base.geometry}
              material={nodes.Rocket_Holder_Base.material}
              skeleton={nodes.Rocket_Holder_Base.skeleton}
            />
          </group>
          <group
            name="Armature_Rocket"
            ref={allRefs.plainRocket}
            position={[1.11, 6.01, -0.43]}
            rotation={[0, 0.29, -0.02]}
          >
            <primitive object={nodes.Root_4} />
            <skinnedMesh
              name="Sphere015"
              frustumCulled={false}
              geometry={nodes.Sphere015.geometry}
              material={materials.M_Rocket}
              skeleton={nodes.Sphere015.skeleton}
            />
            <skinnedMesh
              name="Sphere015_1"
              frustumCulled={false}
              geometry={nodes.Sphere015_1.geometry}
              material={materials.M_RocketWindow}
              skeleton={nodes.Sphere015_1.skeleton}
            />
          </group>
          <primitive object={nodes.Root_5} />
          <skinnedMesh
            name="Car_1"
            frustumCulled={false}
            geometry={nodes.Car_1.geometry}
            material={materials.M_Car}
            skeleton={nodes.Car_1.skeleton}
          />
          <group
            name="Armature_Observatory"
            position={[-4.75, 5.33, 2.95]}
            rotation={[0.1, 0.07, 0.25]}
          >
            <primitive object={nodes.Root_6} />
            <skinnedMesh
              name="Observatory001"
              frustumCulled={false}
              geometry={nodes.Observatory001.geometry}
              material={nodes.Observatory001.material}
              skeleton={nodes.Observatory001.skeleton}
            />
          </group>
          <group
            name="Armature_JoyPad"
            position={[2.88, 4.45, 8.08]}
            rotation={[0.4, -0.3, -0.13]}
          >
            <primitive object={nodes.Root_7} />
            <skinnedMesh
              name="Cube004"
              frustumCulled={false}
              geometry={nodes.Cube004.geometry}
              material={nodes.Cube004.material}
              skeleton={nodes.Cube004.skeleton}
            />
          </group>
          <mesh
            name="Trees017"
            geometry={nodes.Trees017.geometry}
            material={nodes.Trees017.material}
            position={[-7.3, 4.83, -2.7]}
            rotation={[0, -0.29, 0.34]}
          />
          <mesh
            name="Trees020"
            geometry={nodes.Trees020.geometry}
            material={nodes.Trees020.material}
            position={[-8.63, 4.25, 3.01]}
            rotation={[0.07, 0.15, 0.43]}
          />
          <mesh
            name="Trees021"
            geometry={nodes.Trees021.geometry}
            material={nodes.Trees021.material}
            position={[11.52, 2.36, -4.21]}
            rotation={[-0.18, 0.04, -0.61]}
          />
          <mesh
            name="Trees022"
            geometry={nodes.Trees022.geometry}
            material={nodes.Trees022.material}
            position={[9.36, 4.11, 0.59]}
            rotation={[0.03, -0.04, -0.44]}
          />
          <mesh
            name="Trees024"
            geometry={nodes.Trees024.geometry}
            material={nodes.Trees024.material}
            position={[10.21, 3.62, -1.44]}
            rotation={[-0.01, 0.08, -0.54]}
          />
          <mesh
            name="Trees025"
            geometry={nodes.Trees025.geometry}
            material={nodes.Trees025.material}
            position={[12.41, 2.17, -1.86]}
            rotation={[0.08, 0.21, -0.65]}
          />
          <mesh
            name="Tree011"
            geometry={nodes.Tree011.geometry}
            material={nodes.Tree011.material}
            position={[-8.74, 4.41, 0.92]}
            rotation={[0.92, -1.02, 0.95]}
            scale={1.25}
          />
          <mesh
            name="Tree014"
            geometry={nodes.Tree014.geometry}
            material={nodes.Tree014.material}
            position={[-6.34, 5.34, 0.91]}
            rotation={[0.45, -0.85, 0.53]}
            scale={1.34}
          />
          <mesh
            name="Tree015"
            geometry={nodes.Tree015.geometry}
            material={nodes.Tree015.material}
            position={[-8.19, 4.59, -1.74]}
            rotation={[0.83, -1.07, 1.03]}
          />
          <mesh
            name="Tree016"
            geometry={nodes.Tree016.geometry}
            material={nodes.Tree016.material}
            position={[-7.64, 4.89, -0.22]}
            rotation={[-3.03, 0.39, 2.77]}
          />
          <mesh
            name="Tree023"
            geometry={nodes.Tree023.geometry}
            material={nodes.Tree023.material}
            position={[-5.84, 5.48, -0.84]}
            rotation={[2.95, -0.59, 2.84]}
            scale={1.27}
          />
          <mesh
            name="Tree024"
            geometry={nodes.Tree024.geometry}
            material={nodes.Tree024.material}
            position={[-7.26, 4.5, 4.78]}
            rotation={[-2.99, -0.16, 2.75]}
            scale={[1.15, 1.15, 1.15]}
          />
          <mesh
            name="Tree039"
            geometry={nodes.Tree039.geometry}
            material={nodes.Tree039.material}
            position={[9.87, 3.56, -3.22]}
            rotation={[3.06, -0.16, -2.62]}
            scale={1.27}
          />
          <mesh
            name="Tree042"
            geometry={nodes.Tree042.geometry}
            material={nodes.Tree042.material}
            position={[7.88, 4.65, 2.5]}
            rotation={[-2.87, -0.29, -2.7]}
            scale={[1.27, 1.27, 1.27]}
          />
          <mesh
            name="WeirdBuilding001"
            geometry={nodes.WeirdBuilding001.geometry}
            material={nodes.WeirdBuilding001.material}
            position={[6.59, 4.8, -1.61]}
            rotation={[-0.12, -0.04, -0.34]}
            scale={[1.88, 1.88, 1.88]}
          />
          <mesh
            name="Cube003"
            geometry={nodes.Cube003.geometry}
            material={nodes.Cube003.material}
            position={[3.36, 6.03, 0.56]}
            rotation={[-0.06, -0.28, -0.18]}
            scale={[1.2, 1.2, 1.2]}
          />
          <mesh
            name="NurbsPath"
            geometry={nodes.NurbsPath.geometry}
            material={nodes.NurbsPath.material}
            position={[-6.59, 8.34, -0.82]}
          />
          <mesh
            name="NurbsPath001"
            geometry={nodes.NurbsPath001.geometry}
            material={nodes.NurbsPath001.material}
            position={[-3.43, 5.99, 1.32]}
            rotation={[0.05, 0, 0.16]}
          />
          <mesh
            name="Sphere"
            geometry={nodes.Sphere.geometry}
            material={materials.M_Planet}
          />
          <mesh
            name="Trees001"
            geometry={nodes.Trees001.geometry}
            material={nodes.Trees001.material}
            position={[-13.27, 0.8, -5.24]}
            rotation={[0, -0.29, 0.74]}
          />
          <mesh
            name="Tree001"
            geometry={nodes.Tree001.geometry}
            material={nodes.Tree001.material}
            position={[-14.12, 0.82, 2.02]}
            rotation={[1.11, -0.76, 1.17]}
            scale={1.25}
          />
          <mesh
            name="Tree002"
            geometry={nodes.Tree002.geometry}
            material={nodes.Tree002.material}
            position={[-13.13, 1.81, 0.13]}
            rotation={[0.72, -0.68, 0.88]}
            scale={1.34}
          />
          <mesh
            name="Tree003"
            geometry={nodes.Tree003.geometry}
            material={nodes.Tree003.material}
            position={[-14.02, 0.57, -3.63]}
            rotation={[0.99, -0.85, 1.32]}
          />
          <mesh
            name="Tree004"
            geometry={nodes.Tree004.geometry}
            material={nodes.Tree004.material}
            position={[-10.65, 2.34, -6.15]}
            rotation={[-3.14, 0.49, 2.5]}
          />
          <mesh
            name="Tree005"
            geometry={nodes.Tree005.geometry}
            material={nodes.Tree005.material}
            position={[-12.66, 2.04, -1.46]}
            rotation={[2.69, -0.46, 2.41]}
            scale={1.27}
          />
          <mesh
            name="Trees002"
            geometry={nodes.Trees002.geometry}
            material={nodes.Trees002.material}
            position={[2.41, 6.01, -2.35]}
            rotation={[-0.06, 0.52, -0.12]}
          />
          <mesh
            name="Trees003"
            geometry={nodes.Trees003.geometry}
            material={nodes.Trees003.material}
            position={[8.44, 3.46, -6.26]}
            rotation={[-0.4, -0.09, -0.41]}
          />
          <mesh
            name="Tree006"
            geometry={nodes.Tree006.geometry}
            material={nodes.Tree006.material}
            position={[-3.08, 5.37, -5.41]}
            rotation={[2.78, -0.42, 2.91]}
            scale={[1.62, 1.62, 1.62]}
          />
          <mesh
            name="Tree007"
            geometry={nodes.Tree007.geometry}
            material={nodes.Tree007.material}
            position={[-4.78, 5.4, -3.88]}
            rotation={[0.1, -1.02, 0.41]}
            scale={1.34}
          />
          <mesh
            name="Trees004"
            geometry={nodes.Trees004.geometry}
            material={nodes.Trees004.material}
            position={[0.43, 4.01, -9.47]}
            rotation={[-0.47, -0.49, -0.05]}
            scale={1.41}
          />
          <mesh
            name="Tree008"
            geometry={nodes.Tree008.geometry}
            material={nodes.Tree008.material}
            position={[-8.95, 1.57, 10.1]}
            rotation={[-3.06, -0.8, 2.56]}
            scale={1.27}
          />
          <mesh
            name="Trees005"
            geometry={nodes.Trees005.geometry}
            material={nodes.Trees005.material}
            position={[-9.35, 2.34, 8.2]}
            rotation={[0.3, 0.27, 0.5]}
          />
          <mesh
            name="Tree009"
            geometry={nodes.Tree009.geometry}
            material={nodes.Tree009.material}
            position={[-10.25, 1.12, 9.56]}
            rotation={[1.18, -0.79, 0.83]}
            scale={[1.15, 1.15, 1.15]}
          />
          <mesh
            name="Trees008"
            geometry={nodes.Trees008.geometry}
            material={nodes.Trees008.material}
            position={[11.53, 2.31, 4.7]}
            rotation={[-0.28, -0.59, -0.76]}
          />
          <mesh
            name="Trees009"
            geometry={nodes.Trees009.geometry}
            material={nodes.Trees009.material}
            position={[4.87, 3.03, 10.36]}
            rotation={[0.25, -0.76, -0.34]}
          />
          <mesh
            name="Trees010"
            geometry={nodes.Trees010.geometry}
            material={nodes.Trees010.material}
            position={[9.52, 3.13, 5.91]}
            rotation={[0.68, 0.66, -0.61]}
          />
          <mesh
            name="Trees011"
            geometry={nodes.Trees011.geometry}
            material={nodes.Trees011.material}
            position={[-1.51, 1.15, 13.9]}
            rotation={[0.81, -0.82, 0.1]}
          />
          <mesh
            name="Trees012"
            geometry={nodes.Trees012.geometry}
            material={nodes.Trees012.material}
            position={[10.65, 2.16, 6.87]}
            rotation={[0.02, -0.46, -0.63]}
          />
          <mesh
            name="Tree010"
            geometry={nodes.Tree010.geometry}
            material={nodes.Tree010.material}
            position={[9.83, 3.51, 3.91]}
            rotation={[3.03, 0.46, -2.56]}
            scale={1.27}
          />
          <mesh
            name="Tree012"
            geometry={nodes.Tree012.geometry}
            material={nodes.Tree012.material}
            position={[7.25, 1.06, 12.06]}
            rotation={[-2.65, 0.51, -2.77]}
            scale={[1.27, 1.27, 1.27]}
          />
          <mesh
            name="Cube001"
            geometry={nodes.Cube001.geometry}
            material={nodes.Cube001.material}
            position={[10.63, 3.32, 0.83]}
            rotation={[1.22, 1.01, -1.25]}
            scale={[1.6, 1.6, 1.6]}
          />
          <mesh
            name="Trees013"
            geometry={nodes.Trees013.geometry}
            material={nodes.Trees013.material}
            position={[-7.91, 0.71, 12.17]}
            rotation={[0.97, -0.71, 0.43]}
          />
          <mesh
            name="Cube002"
            geometry={nodes.Cube002.geometry}
            material={nodes.Cube002.material}
            position={[-7.26, 2.15, 10.48]}
            rotation={[-0.78, 1.17, 1.33]}
            scale={[1.38, 1.38, 1.38]}
          />
          <mesh
            name="Trees014"
            geometry={nodes.Trees014.geometry}
            material={nodes.Trees014.material}
            position={[-3.01, 2.08, 12.48]}
            rotation={[0.79, -0.75, 0.24]}
          />
          <mesh
            name="Tree013"
            geometry={nodes.Tree013.geometry}
            material={nodes.Tree013.material}
            position={[-3.82, 0.84, 13.81]}
            rotation={[1.01, -0.98, 0.36]}
            scale={1.25}
          />
          <mesh
            name="House_02001"
            geometry={nodes.House_02001.geometry}
            material={nodes.House_02001.material}
            position={[2.57, 4.83, -7.24]}
            rotation={[-0.4, -0.53, -0.12]}
            scale={[1.46, 1.46, 1.46]}
          />
          <mesh
            name="Grass001"
            geometry={nodes.Grass001.geometry}
            material={nodes.Grass001.material}
            position={[3.75, 2.57, 11.55]}
            rotation={[0.73, 0.56, -0.21]}
            scale={0.87}
          />
          <mesh
            name="Grass002"
            geometry={nodes.Grass002.geometry}
            material={nodes.Grass002.material}
            position={[-2.02, 4.91, 7.44]}
            rotation={[0.34, -0.1, 0.04]}
            scale={0.87}
          />
          <mesh
            name="Grass003"
            geometry={nodes.Grass003.geometry}
            material={nodes.Grass003.material}
            position={[-3.61, 2.89, 11.1]}
            rotation={[0.53, -0.08, 0.15]}
            scale={0.87}
          />
          <mesh
            name="Grass004"
            geometry={nodes.Grass004.geometry}
            material={nodes.Grass004.material}
            position={[7.9, 4.14, 4.9]}
            rotation={[0.57, 0.66, -0.51]}
            scale={0.9}
          />
          <mesh
            name="Grass005"
            geometry={nodes.Grass005.geometry}
            material={nodes.Grass005.material}
            position={[5.53, 5.48, 2.15]}
            rotation={[-0.04, -0.58, -0.24]}
            scale={[1.23, 1.23, 1.23]}
          />
          <mesh
            name="Grass006"
            geometry={nodes.Grass006.geometry}
            material={nodes.Grass006.material}
            position={[3.82, 5.6, -3.72]}
            rotation={[-0.19, 0.07, -0.16]}
            scale={[1.08, 1.08, 1.08]}
          />
          <mesh
            name="Grass007"
            geometry={nodes.Grass007.geometry}
            material={nodes.Grass007.material}
            position={[12.1, 2.5, -0.45]}
            rotation={[0.02, 0.12, -0.64]}
            scale={[1.08, 1.08, 1.08]}
          />
          <mesh
            name="Grass008"
            geometry={nodes.Grass008.geometry}
            material={nodes.Grass008.material}
            position={[-7.61, 2.95, 8.71]}
            rotation={[0.28, 0.42, 0.37]}
            scale={[0.93, 0.93, 0.93]}
          />
          <mesh
            name="Grass"
            geometry={nodes.Grass.geometry}
            material={nodes.Grass.material}
            position={[-6.03, 4.69, 5.67]}
            rotation={[-2.41, 0.93, 2.66]}
            scale={[0.93, 0.93, 0.93]}
          />
        </group>
        {children}
      </group>
    </group>
  )
}
