import { useGLTF } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components/macro'
import { v4 as uuidv4 } from 'uuid'
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from '@reach/router'

import HomeText from '../components/Home/HomeText'
import Loader from '../components/Home/Loader'
import Scenes from '../components/Home/Scenes'
import useScrollDirection from '../hooks/useScrollDirection'
import { useScrollProgress } from '../hooks/useScrollProgress'
import Layout from '../components/Layout/Layout'
import EngagementMessage from '../components/Home/EngagementMessage'
import ArrowDown from '../components/common/Arrows/ArrowDown'
import AwardsRibbon from '../components/Home/AwardsRibbon'

import { Device } from '../interfaces/three.d'
import breakpoints from '../styles/breakpoints'
import theme from '../theme/theme'
import useBreakpoint from '../hooks/useBreakpoint'
import SEO from '../components/Layout/SEO/SEO'

export default function Homepage() {
  const canvas = useRef<HTMLCanvasElement>(null)
  const [currentMediaQuery, setCurrentMediaQuery] = useState<Device>(
    Device.Tablet
  )
  const [isLoaded, setIsLoaded] = useState<boolean>(true)

  // Scroll based updates
  const [currScrollElement, setCurrScrollElement] =
    useState<HTMLDivElement | null>(null)
  const scrollElement = useRef<HTMLDivElement>(null)
  const scrollDirection = useScrollDirection(undefined, 1)
  const [currentAnimation, setCurrentAnimation] = useState<number>(0)
  const [clickCount, setClickCount] = useState<number>(0)
  // With "6" being the end frame => Animation 6 === animation flow finished
  const animationSetCount = 6
  const currentAnimationIndex = useScrollProgress(
    currScrollElement,
    animationSetCount
  )
  const isTextCentered = currentAnimation === 0
  const isTextLeftAligned =
    currentAnimation === 2 ||
    currentAnimation === 4 ||
    currentAnimation === 5 ||
    currentAnimation === 6
  const isLastFrame = currentAnimation === 5 || currentAnimation === 6

  const isHomepage = useLocation().pathname === '/'
  // prevents a rerendering of the text if 100% of canvas is scrolled (last index signals that we are at the end of the animations)
  const textKey = Math.min(currentAnimation, animationSetCount - 1).toString()

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.size.tablet})`, // Custom query to provide mobile query up to 768px
  })
  const isTablet = useMediaQuery({ query: breakpoints.device.tablet })
  const isDesktop_sm = useMediaQuery({ query: breakpoints.device.desktop_sm })
  const isLaptop = useMediaQuery({ query: breakpoints.device.laptop })
  const isDesktop_md = useMediaQuery({ query: breakpoints.device.desktop_md })
  const isDesktop_lg = useMediaQuery({ query: breakpoints.device.desktop_lg })

  // Engagement button
  const pullClickCount = (clicks: React.SetStateAction<number>) => {
    setClickCount(clicks)
  }
  const isDesktop = useBreakpoint('sm')

  // Places the element ref inside a state to force a reinstantiation of the scrollProgress hook
  useEffect(() => {
    if (scrollElement.current) {
      setCurrScrollElement(scrollElement.current)
    }
  }, [scrollElement.current])

  // Update AnimationSet
  useEffect(() => {
    const animationIndexDelta = currentAnimation - currentAnimationIndex
    const isDeltaMoreThanOne = Math.abs(animationIndexDelta) > 1

    // Prevents a rerender of the scene if 100% of canvas is scrolled (last index signals that we are at the end of the animations)
    const lastAnimationFrame = animationSetCount - 1
    if (
      currentAnimationIndex != currentAnimation &&
      !isDeltaMoreThanOne &&
      currentAnimationIndex <= lastAnimationFrame
    ) {
      setCurrentAnimation(currentAnimationIndex)
    }
  }, [currentAnimationIndex])

  // initialize correct media query
  useEffect(() => {
    if (isMobile) {
      setCurrentMediaQuery(Device.Mobile)
    } else if (isTablet) {
      setCurrentMediaQuery(Device.Tablet)
    } else if (isDesktop_sm) {
      setCurrentMediaQuery(Device.Desktop_sm)
    } else if (isLaptop) {
      setCurrentMediaQuery(Device.Laptop)
    } else if (isDesktop_md) {
      setCurrentMediaQuery(Device.Desktop_md)
    } else if (isDesktop_lg) {
      setCurrentMediaQuery(Device.Desktop_lg)
    }
  }, [isMobile, isTablet, isDesktop_sm, isLaptop, isDesktop_md, isDesktop_lg])

  const [activeMessage, setActiveMessage] = useState<number | null>(null)

  useEffect(() => {
    if (clickCount > 0) {
      if (clickCount === 3) {
        setActiveMessage(1)
      } else if (clickCount === 5) {
        setActiveMessage(2)
      } else setActiveMessage(0)
    }
  }, [clickCount])

  const getTextTheme = () => {
    switch (currentAnimation) {
      case 0:
        return 'dark'
      case 1:
        return 'dark'
      case 2:
        return 'dark'
      case 3:
        return 'dark'
      case 4:
        return 'dark'
      case 5:
        return 'light'
      case 6:
        return 'light'
      default:
        return 'dark'
    }
  }

  const getArrowColor = () => {
    switch (currentAnimation) {
      case 4:
        return theme.palette.global.white
      case 5:
        return theme.palette.global.white
      case 6:
        return theme.palette.global.white
      default:
        return theme.palette.global.darkGrey
    }
  }

  return (
    <>
      <SEO
        title="Pop Rocket | Die Digitalagentur für Engagement"
        description="Wir liefern Interaktionen, die begeistern. Gamification, motivierendes Design und maßgeschneiderte Entwicklung sorgen für unvergessliche digitale Erlebnisse."
        tabTitle="Planet Experience"
      />
      <Loader setIsLoaded={setIsLoaded} />
      <Layout isTextWhite={currentAnimation >= 5} overflow="unset">
        <AwardsRibbon />
        <StyledCanvasContainer ref={scrollElement} className="canvas-container">
          <StyledCanvasReveal isLoaded={isLoaded}>
            <StyledCanvasWrapper bgColor={currentAnimation >= 5 ? 1 : 0}>
              <StyledProgressContainer>
                <StyledProgressBar
                  progress={currentAnimation * (1 / (animationSetCount - 1))}
                />
              </StyledProgressContainer>
              <StyledTextContainer>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.75 }}
                    key={textKey}
                  >
                    <HomeText
                      textTheme={getTextTheme()}
                      isLastFrame={isLastFrame}
                      currentAnimation={currentAnimation}
                      isCentered={isTextCentered}
                      isLeftAligned={isTextLeftAligned}
                    />
                  </motion.div>
                </AnimatePresence>
                <StyledArrowContainer>
                  <ArrowDown
                    color={getArrowColor()}
                    showArrow={!isLastFrame}
                    isHomepage={isHomepage}
                    isLeftAligned={false}
                    isCentered={true}
                    isFirstAnimation={currentAnimation === 0}
                  />
                </StyledArrowContainer>
                {currentAnimation === 1 && clickCount > 0 && (
                  <AnimatePresence key={uuidv4()}>
                    <motion.div
                      initial={{ opacity: 0, translateY: '100vh' }}
                      animate={{
                        opacity: 1,
                        translateY: isDesktop ? '-10vh' : '0vh',
                      }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.75 }}
                      key={uuidv4()}
                    >
                      <EngagementMessage clickCount={clickCount} />
                    </motion.div>
                  </AnimatePresence>
                )}
              </StyledTextContainer>
              <Canvas
                camera={{ position: [0, 0, 90], fov: 400 }}
                onCreated={({ gl }) => gl.setClearColor(0xffffff, 0)}
                dpr={
                  typeof window !== 'undefined'
                    ? Math.min(window.devicePixelRatio, 2)
                    : undefined
                }
                linear
                ref={canvas}
              >
                <Suspense fallback={null}>
                  <ambientLight intensity={1.5} />
                  <Scenes
                    currentAnimation={currentAnimation}
                    scrollDirection={scrollDirection}
                    currentMediaQuery={currentMediaQuery}
                    canvasRef={canvas}
                    getClickCount={pullClickCount}
                  />
                </Suspense>
              </Canvas>
            </StyledCanvasWrapper>
          </StyledCanvasReveal>
        </StyledCanvasContainer>
      </Layout>
    </>
  )
}

// ? Preload all models
useGLTF.preload('/models/glb/Human.glb', true)
useGLTF.preload('/models/glb/PlanetScene.glb', true)
useGLTF.preload('/models/glb/RocketAssembly.glb', true)
useGLTF.preload('/models/glb/SpaceRocket.glb', true)
useGLTF.preload('/models/glb/HeroPlanet.glb', true)

const StyledProgressContainer = styled.div`
  background: #e0e0e0;
  height: 8rem;
  position: absolute;
  bottom: 12rem;
  left: 1rem;
  width: 0.5rem;
  z-index: 99;
  border-radius: 5rem;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    bottom: 3rem;
    width: 0.3rem;
  }
`

const StyledProgressBar = styled.div<{ progress: number }>`
  position: absolute;
  top: ${({ progress }) => `${-100 + progress * 100}%`};
  border-radius: 5rem;
  width: 0.5rem;
  background: ${({ theme }) => theme.palette.company.prlOrange};
  height: 100%;
  transition: top 0.5s ease-in-out;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    width: 0.3rem;
  }
`

const StyledCanvasContainer = styled.div`
  width: 100vw;
  height: 300vh;
  position: relative;
  margin-top: -8rem;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    margin-top: -14rem;
  }
`
const StyledCanvasReveal = styled.div<{ isLoaded: boolean }>`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.palette.global.grey};
  z-index: 999;
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
`

const StyledCanvasWrapper = styled.div<{ bgColor: number }>`
  width: 100vw;
  height: 100vh;
  position: sticky;
  top: 0;
  &:before {
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: #1f3e5a;
    opacity: ${({ bgColor }) => bgColor};
    transition: opacity 2s ease-in-out;
  }
`

const StyledTextContainer = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 118rem;
  background: transparent;
  z-index: 1;
  pointer-events: none;
`
const StyledArrowContainer = styled.div`
  position: absolute;
  bottom: 1.75rem;
  left: 50%;
  transform: translateX(-50%);
`
