import { SkinnedMesh } from 'three'
import { GLTF } from 'three-stdlib'

// Extend GLTF interface with nodes & materials to match R3F's useLoader output (used by useGLTFLoader)
export interface DreiGLTF extends GLTF {
  nodes: Record<string, gltfMesh>
  materials: Record<string, THREE.MeshStandardMaterial>
}

// SkinnedMesh extends the basic THREE.Mesh interface with e.g. skeleton props
type gltfMesh = SkinnedMesh & THREE.Mesh

export interface SubclipBase {
  clip: THREE.AnimationClip | undefined
  name: string
}

// Hook related types
export enum Device {
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop_sm = 'Desktop_sm',
  Laptop = 'Laptop',
  Desktop_md = 'Desktop_md',
  Desktop_lg = 'Desktop_lg',
}

export enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}

// AnimationSet related types
export type AnimationSet = {
  id: number
  blockingTime: {
    forwardDuration: number | null
    reverseDuration: number | null
  }
  modelSets: Map<Model, ModelSet>
}
type ModelSet = { actions?: ActionSet; movementSets?: MovementSets }
type ActionSet = Map<ScrollDirection, () => void>
export type MovementSets = Map<Device, MovementSet>

export type MovementSet = {
  position?: {
    data: THREE.Vector3
    duration?: number
    delay?: number
  }
  rotation?: {
    data: THREE.Euler
    duration?: number
    delay?: number
  }
  scale?: {
    data: THREE.Vector3
    duration?: number
    delay?: number
  }
}

// Action related types
export type AllActions = PlanetActions &
  HumanActions &
  RocketAssemblyActions &
  RocketActions

export type PlanetActions = {
  // custom actions
  openPlanetAction_L: THREE.AnimationAction | null
  openPlanetAction_R: THREE.AnimationAction | null

  // model actions
  Armature_FerrisWheelAction: THREE.AnimationAction | null
  ['Action.001']: THREE.AnimationAction | null
  Armature_BaloonAction: THREE.AnimationAction | null
  ['Armature_WindTurbine_01Action.001']: THREE.AnimationAction | null
  Armature_TelescopeAction: THREE.AnimationAction | null
  Action: THREE.AnimationAction | null
  Armature_Clouds_LAction: THREE.AnimationAction | null
  Armature_Clouds_RAction: THREE.AnimationAction | null
  ['Armature_ArrowAction.001']: THREE.AnimationAction | null
}

export type HumanActions = {
  // custom actions
  reveal: THREE.AnimationAction | null
  idle: THREE.AnimationAction | null
  idleAndWave: THREE.AnimationAction | null
}
export type RocketAssemblyActions = {
  // model actions
  Armature_MechanicalArmAction: THREE.AnimationAction | null
  ['Action.002']: THREE.AnimationAction | null
  Armature_CarAction: THREE.AnimationAction | null
  ['Armature_Engineer_01.001Action']: THREE.AnimationAction | null
  ['Armature_Engineer_01.002Action']: THREE.AnimationAction | null
  Armature_Engineer_01Action: THREE.AnimationAction | null
  Armature_JoyPadAction: THREE.AnimationAction | null
  ['Armature_ObservatoryAction.001']: THREE.AnimationAction | null
  Armature_SkyAction: THREE.AnimationAction | null
}
export type RocketActions = {
  // custom actions
  rocketIdle: THREE.AnimationAction | null
  rocketStart: THREE.AnimationAction | null

  // model actions
  ['Armature_StarsAction.001']: THREE.AnimationAction | null
}

// Ref related types
export type Model = React.RefObject<THREE.Group>

export type AllRefs = RocketAssemblyRefs & AllPlanetRefs & AllRocketRefs

export interface RocketAssemblyRefs {
  rocketAssemblyScene: React.MutableRefObject<THREE.Group | null>
  rocketAssemblyPlanet: React.MutableRefObject<THREE.Group | null>
  plainRocket: React.MutableRefObject<THREE.Group | null>
}
export interface AllPlanetRefs {
  planetScene: React.MutableRefObject<THREE.Group | null>
  planet: React.MutableRefObject<THREE.Group | null>
  planetLeft: React.MutableRefObject<THREE.Group | null>
  planetRight: React.MutableRefObject<THREE.Group | null>
  human: React.MutableRefObject<THREE.Group | null>
}

export interface AllRocketRefs {
  rocket: React.MutableRefObject<THREE.Group | null>
  stars: React.MutableRefObject<THREE.Group | null>
}
