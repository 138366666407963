import { useState, useEffect } from 'react'
import { throttle, debounce } from 'throttle-debounce'

interface ScrollPosition {
  y: number
  relativeY: number | undefined
}

const isWindowLoaded = typeof window !== `undefined`

export function useScrollProgress(
  reference: HTMLDivElement | null,
  chunkCount: number
): number {
  const [verticalScrollProgress, setVerticalScrollProgress] = useState<number>(
    updateScrollProgress(getScrollPosition(reference), chunkCount)
  )

  useEffect(() => {
    function handleScroll() {
      const scrollPosition = getScrollPosition(reference)
      const scrollProgress = updateScrollProgress(scrollPosition, chunkCount)
      if (scrollProgress !== verticalScrollProgress) {
        setVerticalScrollProgress(scrollProgress)
      }
    }
    if (isWindowLoaded) {
      window.addEventListener('scroll', debounce(10, handleScroll))
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [reference, verticalScrollProgress])

  return verticalScrollProgress
}

function updateScrollProgress(
  scrollPosition: ScrollPosition,
  chunkCount: number
): number {
  const { relativeY } = scrollPosition

  if (relativeY === undefined) return 0

  const chunk = 1 / chunkCount

  const chunkedProgress: number =
    Math.round(Math.round(relativeY / chunk) * chunk * 100) / 100

  const progressIndex = Math.round(chunkedProgress * chunkCount)

  return progressIndex
}

function getScrollPosition(reference: HTMLDivElement | null): ScrollPosition {
  const relativeYOffset: number = getRelativeOffset(reference)

  if (isWindowLoaded && reference !== null) {
    return {
      y: window.scrollY,
      relativeY: relativeYOffset,
    }
  }
  if (isWindowLoaded) {
    return {
      y: window.scrollY,
      relativeY: undefined,
    }
  }

  return { y: 0, relativeY: undefined }
}

function getRelativeOffset(reference: HTMLDivElement | null): number {
  if (!reference) return 0

  let roundedRelativeYOffset: number

  const elYScrollDistance: number = window.pageYOffset - reference.offsetTop
  const elHeight: number = reference.offsetHeight
  const relativeYOffset: number =
    elYScrollDistance / (elHeight - window.innerHeight) //0 if top line crosses top viewport <> 1 if bottom line crosses bottom viewport
  roundedRelativeYOffset = Math.round(relativeYOffset * 100) / 100

  if (roundedRelativeYOffset < 0) roundedRelativeYOffset = 0
  if (roundedRelativeYOffset > 1) roundedRelativeYOffset = 1

  return roundedRelativeYOffset
}
