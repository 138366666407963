const size = {
  mobile: '376px',
  tablet: '768px',
  desktop_sm: '1023px',
  laptop: '1220px',
  desktop_md: '1440px',
  desktop_lg: '1920px',
}
const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  desktop_sm: `(min-width: ${size.desktop_sm})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop_md: `(min-width: ${size.desktop_md})`,
  desktop_lg: `(min-width: ${size.desktop_lg})`,
}
export default { size, device }
