import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import theme from '../../theme/theme'
import useBreakpoint from '../../hooks/useBreakpoint'
import Typography from '../common/Typography'
import ArrowDownHomeTextLink from '../common/Arrows/ArrowDownHomeTextLink'

interface HomeTextProps {
  textTheme: 'dark' | 'light'
  isLeftAligned: boolean
  isCentered: boolean
  isLastFrame: boolean
  currentAnimation: number
}

export default function HomeText({
  isLeftAligned,
  isCentered,
  currentAnimation,
  isLastFrame = false,

  textTheme,
}: HomeTextProps) {
  const { t } = useTranslation()
  const isDesktop = useBreakpoint('sm')
  const isFirstAnimation = currentAnimation === 0
  const isLightTextTheme = textTheme === 'light'

  const getTextColor = (): string => {
    switch (currentAnimation) {
      case 0:
        return ''
      case 1:
        return theme.palette.company.prlOrange
      case 2:
        return theme.palette.company.prlRed
      case 3:
        return theme.palette.company.prlBlue
      case 4:
        return theme.palette.company.prlYellow
      case 5:
        return ''
      case 6:
        return ''
      default:
        return ''
    }
  }

  const getHeadingText = (): string => {
    switch (currentAnimation) {
      case 0:
        return t('homepage.animation0.heading')
      case 1:
        return t('homepage.animation1.heading')
      case 2:
        return t('homepage.animation2.heading')
      case 3:
        return t('homepage.animation3.heading')
      case 4:
        return t('homepage.animation4.heading')
      case 5:
        return t('homepage.animation5.heading')
      case 6:
        return t('homepage.animation5.heading')
      default:
        return ''
    }
  }

  const getCopyText = (): string => {
    switch (currentAnimation) {
      case 0:
        return t('homepage.animation0.copy')
      case 1:
        return t('homepage.animation1.copy')
      case 2:
        return t('homepage.animation2.copy')
      case 3:
        return t('homepage.animation3.copy')
      case 4:
        return t('homepage.animation4.copy')
      case 5:
        return t('homepage.animation5.copy')
      case 6:
        return t('homepage.animation5.copy')
      default:
        return ''
    }
  }

  const getPrincipleText = (): string => {
    switch (currentAnimation) {
      case 1:
        return t('homepage.animation1.principle')
      case 2:
        return t('homepage.animation2.principle')
      case 3:
        return t('homepage.animation3.principle')
      case 4:
        return t('homepage.animation4.principle')
      default:
        return ''
    }
  }

  return (
    <>
      <StyledTextContainer
        isLeftAligned={isLeftAligned}
        isCentered={isCentered}
        isFirstAnimation={isFirstAnimation}
      >
        <StyledHeadingWrapper
          isFirstAnimation={isFirstAnimation}
          isLastFrame={isLastFrame}
        >
          <StyledPrinciple type="overhead" textColor={getTextColor()}>
            {getPrincipleText()}
          </StyledPrinciple>

          <StyledHeading
            type={isDesktop ? 'h1' : 'h1Mobile'}
            isWhite={isLightTextTheme}
            isFirstAnimation={isFirstAnimation}
          >
            {getHeadingText()}
          </StyledHeading>
          {isFirstAnimation && (
            <StyledHeading
              type={isDesktop ? 'h1' : 'h1Mobile'}
              isWhite={isLightTextTheme}
              isFirstAnimation={false}
            >
              {t('homepage.animation0.heading2')}
            </StyledHeading>
          )}
        </StyledHeadingWrapper>
        <StyledCopy
          type={isDesktop ? 'copyBig' : 'copyBigMobile'}
          isWhite={isLightTextTheme}
          isFirstAnimation={isFirstAnimation}
        >
          {getCopyText()} <br />
        </StyledCopy>
        <ArrowDownHomeTextLink
          color={theme.palette.global.darkGrey}
          isFirstAnimation={isFirstAnimation}
        />
      </StyledTextContainer>
    </>
  )
}

const StyledTextContainer = styled.div<{
  isLeftAligned: boolean
  isCentered: boolean
  isFirstAnimation: boolean
}>`
  ${({ isLeftAligned }) => (isLeftAligned ? 'left: 0' : 'right: 0')};
  ${({ isCentered }) =>
    isCentered
      ? 'left: 0; right: 0; margin-left: auto; margin-right: auto;'
      : ''};
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: ${({ isFirstAnimation }) =>
    isFirstAnimation ? '8rem 2.4rem 0 2.4rem' : '6rem 2.4rem 0 2.4rem'};
  display: flex;
  flex-direction: column;
  text-align: center;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: ${({ isLeftAligned }) =>
      isLeftAligned ? '6rem 2.4rem 0 6rem' : '6rem 2.4rem 0 10rem'};
    width: ${({ isFirstAnimation }) => (isFirstAnimation ? '70%' : '50%')};
    padding-top: 0;
    text-align: ${({ isCentered }) => (isCentered ? 'center' : 'left')};
    ${({ isCentered }) =>
      isCentered
        ? 'left: 0; right: 0; top: -10%; margin-left: auto; margin-right: auto;'
        : ''};
  }
`

const StyledHeadingWrapper = styled.div<{
  isFirstAnimation: boolean
  isLastFrame: boolean
}>`
  flex-direction: column;
  justify-content: flex-end;
  display: block;
  height: auto;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    height: 50%;
    display: flex;
    max-width: ${({ isFirstAnimation, isLastFrame }) =>
      isFirstAnimation || isLastFrame ? 'initial' : '37.5rem'};
  }
`

const StyledHeading = styled(Typography)<{
  isWhite: boolean
  isFirstAnimation: boolean
}>`
  font-size: ${({ isFirstAnimation }) =>
    isFirstAnimation ? '2.4rem' : '3.3rem'};
  line-height: ${({ isFirstAnimation }) =>
    isFirstAnimation ? '2.8rem' : '4.2rem'};
  color: ${({ isWhite }) =>
    isWhite ? theme.palette.global.white : theme.palette.global.accent};
  padding: ${({ isFirstAnimation }) => (isFirstAnimation ? '0' : '0 0 2rem')};
  font-weight: 500;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    padding: ${({ isFirstAnimation }) =>
      isFirstAnimation ? '0' : '0 0 3.2rem'};
    font-size: ${({ isFirstAnimation }) =>
      isFirstAnimation ? '4rem' : '5.3rem'};
    line-height: 5.2rem;
    max-width: initial;
  }
`

const StyledCopy = styled(Typography)<{
  isWhite: boolean
  isFirstAnimation: boolean
}>`
  max-width: ${({ isFirstAnimation }) =>
    isFirstAnimation ? '30rem' : 'initial'};
  margin: ${({ isFirstAnimation }) =>
    isFirstAnimation ? '0 auto' : 'initial'};
  font-weight: ${({ isFirstAnimation }) =>
    isFirstAnimation ? '500!important' : '400'};
  color: ${({ isWhite }) =>
    isWhite ? theme.palette.global.white : theme.palette.company.prlMiddleGrey};
  ${({ theme }) => theme.breakpoints.queries.sm} {
    max-width: ${({ isFirstAnimation }) =>
      isFirstAnimation ? '55rem' : '33rem'};
  }
`
const StyledPrinciple = styled(Typography)<{ textColor: string | undefined }>`
  text-transform: uppercase;
  font-weight: 700 !important;
  margin: 0 0 1rem;
  color: ${({ textColor }) => textColor};
`
