import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { GroupProps } from '@react-three/fiber'
import { Vector3 } from 'three'

import {
  AllActions,
  AllRefs,
  Device,
  ScrollDirection,
} from '../../interfaces/three.d'
import RocketAssembly from './Scenes/RocketAssembly'
import PlanetScene from './Scenes/PlanetScene'
import FullPageTransition from './FullPageTransition'
import RocketInSpace from './Scenes/RocketInSpace'
import { ModelProvider } from './ModelProvider'

interface SceneProps extends GroupProps {
  currentAnimation: number
  scrollDirection: ScrollDirection
  currentMediaQuery: Device
  canvasRef: React.RefObject<HTMLCanvasElement>
  getClickCount: (click: number) => void
}

export default function Scenes({
  currentAnimation,
  scrollDirection,
  currentMediaQuery,
  canvasRef,
  getClickCount,
}: SceneProps): ReactElement {
  const [isRocketMoving, setIsRocketMoving] = useState(false)
  const [models, setModels] = useState<ModelProvider | null>(null)
  const modelRef = useRef(models)
  const [isFullTransitionActive, setIsFullTransitionActive] =
    useState<boolean>(false)
  const fullPageTransitionDuration =
    scrollDirection === ScrollDirection.Up ? 0.5 : 0.75
  const revealDelay = scrollDirection === ScrollDirection.Up ? 0.5 : 0.25
  const fullPageScrollDisableDuration =
    scrollDirection === ScrollDirection.Up ? 2 : 5.75

  // Engagement button
  const [buttonClicked, setButtonClicked] = useState<boolean>(false)
  const [clickCounter, setClickCounter] = useState<number>(0)

  const handleClickButton = () => {
    setButtonClicked(true)
    setClickCounter(clickCounter + 1)
  }
  useEffect(() => {
    getClickCount(clickCounter)
  })

  useEffect(() => {
    if (clickCounter > 5) {
      setButtonClicked(false)
      setClickCounter(1)
    }
  }, [buttonClicked, clickCounter])

  useEffect(() => {
    setClickCounter(0)
  }, [currentAnimation])

  // Initialize all refs
  const allRocketAssemblyRefs = {
    rocketAssemblyScene: useRef<THREE.Group>(null),
    rocketAssemblyPlanet: useRef<THREE.Group>(null),
    plainRocket: useRef<THREE.Group>(null),
  }
  const allPlanetRefs = {
    planetScene: useRef<THREE.Group>(null),
    planet: useRef<THREE.Group>(null),
    planetLeft: useRef<THREE.Group>(null),
    planetRight: useRef<THREE.Group>(null),
    human: useRef<THREE.Group>(null),
  }
  const allRocketRefs = {
    rocket: useRef<THREE.Group>(null),
    stars: useRef<THREE.Group>(null),
  }

  // Initialize all actions
  const planetActions = {
    openPlanetAction_L: null,
    openPlanetAction_R: null,
    Armature_FerrisWheelAction: null,
    ['Action.001']: null,
    Armature_BaloonAction: null,
    ['Armature_WindTurbine_01Action.001']: null,
    Armature_TelescopeAction: null,
    Action: null,
    Armature_Clouds_LAction: null,
    Armature_Clouds_RAction: null,
    ['Armature_ArrowAction.001']: null,
  }

  const humanActions = {
    reveal: null,
    idle: null,
    idleAndWave: null,
  }
  const rocketAssemblyActions = {
    Armature_MechanicalArmAction: null,
    ['Action.002']: null,
    Armature_CarAction: null,
    ['Armature_Engineer_01.001Action']: null,
    ['Armature_Engineer_01.002Action']: null,
    Armature_Engineer_01Action: null,
    Armature_JoyPadAction: null,
    ['Armature_ObservatoryAction.001']: null,
    Armature_SkyAction: null,
  }
  const rocketActions = {
    rocketIdle: null,
    rocketStart: null,
    ['Armature_StarsAction.001']: null,
  }

  // Initialize the models
  useEffect(() => {
    const allActions: AllActions = {
      ...planetActions,
      ...humanActions,
      ...rocketAssemblyActions,
      ...rocketActions,
    }
    const allRefs: AllRefs = {
      ...allPlanetRefs,
      ...allRocketRefs,
      ...allRocketAssemblyRefs,
    }

    if (models === null) {
      const modelSet = new ModelProvider({ ...allRefs }, { ...allActions })
      modelSet.createAnimationSet()

      setModels(modelSet)
    }
  }, [])

  useEffect(() => {
    return () => {
      models?.resetAnimations(currentMediaQuery)
    }
  }, [models])

  // Play Animations based on the current animation index
  useEffect(() => {
    // ToDo Remove for cleanup
    if (!models) return

    if (models.animationSet) {
      models.playAnimation(
        canvasRef,
        currentAnimation,
        scrollDirection,
        currentMediaQuery
      )
    }
    if (
      (currentAnimation === 4 && scrollDirection === 'down') ||
      (currentAnimation === 3 && scrollDirection === 'up')
    ) {
      setIsFullTransitionActive(true)
      models.disableScroll(
        fullPageScrollDisableDuration,
        canvasRef,
        'transition'
      )
      setTimeout(() => {
        setIsFullTransitionActive(false)
      }, (fullPageTransitionDuration + revealDelay) * 1000)
    }

    if (currentAnimation >= 5 && !isRocketMoving) {
      setIsRocketMoving(true)
    } else {
      if (isRocketMoving) {
        setIsRocketMoving(false)
      }
    }
  }, [currentAnimation, models])

  // Provide a ref to the models to be able to dispatch the state on unmount
  useEffect(() => {
    modelRef.current = models
  }, [models])

  // Always start at the top on reload & reset animations. To start mid animation remove onbeforeunload
  useEffect(() => {
    window.onbeforeunload = () => {
      if (modelRef.current) {
        modelRef.current.forceEnableWindowScroll(canvasRef)
        modelRef.current.resetAnimations(currentMediaQuery)
      }
      window.scrollTo(0, 0)
    }
    window.scrollTo(0, 0)

    return () => {
      if (modelRef.current) {
        modelRef.current.forceEnableWindowScroll(canvasRef)
        modelRef.current.resetAnimations(currentMediaQuery)
      }
      window.scrollTo(0, 0)
    }
  }, [])

  // intercept mousewheel event
  useEffect(() => {
    // Enables only custom scroll tick distances
    const maxWheelDelta = 0

    const handleWheelChange = (e: WheelEvent) => {
      const isContactOpen = document.querySelector('.modal-transition')
      if (isContactOpen) return

      const wheelDelta = Math.abs(e.deltaY)
      if (wheelDelta > maxWheelDelta) {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()

        if (!models?.isScrollDisabled) {
          e.deltaY > 0 ? window.scrollBy(0, 50) : window.scrollBy(0, -50)
        }
      }

      if (models?.isScrollDisabled) {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()
      }
    }
    // not passive to prevent default behavior
    document.body.addEventListener('wheel', handleWheelChange, {
      passive: false,
    })

    return () => {
      document.body.removeEventListener('wheel', handleWheelChange)
    }
  }, [models])

  return (
    <>
      <FullPageTransition
        isActive={isFullTransitionActive}
        duration={fullPageTransitionDuration}
        models={models}
        scrollDirection={scrollDirection}
        currentMediaQuery={currentMediaQuery}
      >
        <PlanetScene
          allPlanetRefs={allPlanetRefs}
          planetActions={planetActions}
          humanActions={humanActions}
          currentAnimation={currentAnimation}
          handleOnButtonClick={() => handleClickButton()}
          currentMediaQuery={currentMediaQuery}
        />

        <RocketAssembly
          allRefs={allRocketAssemblyRefs}
          rocketAssemblyActions={rocketAssemblyActions}
        >
          <RocketInSpace
            allRefs={allRocketRefs}
            rocketActions={rocketActions}
            position={[0.8, 2.4, 0.2]} // ALWAYS align with Movement Set 4 (RocketInSpace) & models.resetAnimation
            rotation={[0, -Math.PI * 0.125, 0]} // ALWAYS align with Movement Set 4 (RocketInSpace) & models.resetAnimation
            visible={false}
            drivePosition={new Vector3(0, 2, 0)}
            starsProps={{
              position: new Vector3(0, 50, 5),
            }}
            isMoving={isRocketMoving}
            movingStrength={3}
          />
        </RocketAssembly>

        <pointLight position={[30, 10, 0]} color={0x00ffff} />
      </FullPageTransition>
    </>
  )
}
