// Check the Loader component from three/Drei for a more complex variant
// This component is written to have a 100% customizable version
// https://github.com/pmndrs/drei/blob/master/src/web/Loader.tsx

import React, {
  Dispatch,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useProgress } from '@react-three/drei'
import styled from 'styled-components/macro'

import disabledScrollListener from 'disable-scroll'

import LoaderGif from '../../assets/images/PRL_Loader.gif'

interface LoaderOptions {
  dataInterpolation?: (p: number) => string
  initialState?: (active: boolean) => boolean
  setIsLoaded?: Dispatch<React.SetStateAction<boolean>>
}

const defaultDataInterpolation = (p: number) => `Loading ${p.toFixed(2)}%`

export default function LoaderNew({
  dataInterpolation = defaultDataInterpolation,
  initialState = (active: boolean) => active,
  setIsLoaded,
}: LoaderOptions) {
  const { active, progress } = useProgress()
  const progressRef = useRef(0)
  const rafRef = useRef(0)
  const progressSpanRef = useRef<HTMLSpanElement>(null)
  const [shown, setShown] = useState(initialState(active))

  useEffect(() => {
    if (shown && setIsLoaded) setIsLoaded(false)
    shown ? disabledScrollListener.on() : disabledScrollListener.off()
    let t: NodeJS.Timeout
    if (active !== shown) t = setTimeout(() => setShown(active), 300)
    return () => {
      if (setIsLoaded) setIsLoaded(true)
      window.scrollTo(0, 0)
      clearTimeout(t)
    }
  }, [shown, active])

  const updateProgress = useCallback(() => {
    if (!progressSpanRef.current) return

    progressRef.current += (progress - progressRef.current) / 2

    if (progressRef.current > 0.95 * progress || progress === 100)
      progressRef.current = progress

    progressSpanRef.current.innerText = dataInterpolation(progressRef.current)

    if (progressRef.current < progress)
      rafRef.current = requestAnimationFrame(updateProgress)
  }, [dataInterpolation, progress])

  useEffect(() => {
    updateProgress()
    return () => cancelAnimationFrame(rafRef.current)
  }, [updateProgress])

  return shown ? (
    <StyledDiv>
      <img height="112px" width="112px" src={LoaderGif} alt="loading" />
    </StyledDiv>
  ) : null
}

const StyledDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #f6f6f6;
  z-index: 999999;
  display: grid;
  place-items: center;
`
