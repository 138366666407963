import * as THREE from 'three'
import { SubclipBase } from '../../interfaces/three.d'

/**
 * It returns a promise that resolves after a given number of seconds
 * @param {number} seconds - The number of seconds to wait before resolving the promise.
 * @returns A promise that resolves after a certain amount of time.
 */
export function delay(seconds: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, seconds * 1000))
}

/**
 * It takes a list of subclip bases, and for each subclip base, it creates a subclip  and adds the subclip to the list of animations
 * @param {SubclipBase[]} subclipBase - This is an array of objects that contain the clip and name of
 * the subclip.
 * @param {THREE.AnimationClip[]} animations - The array of animations to add the subclip to.
 * @param {number} startFrame - The frame number to start the subclip at.
 * @param {number} endFrame - The last frame of the subclip.
 * @param {number} framesPerSecond - The number of frames per second in the animation.
 */
export function createSubclips(
  subclipBase: SubclipBase[],
  animations: THREE.AnimationClip[],
  startFrame: number,
  endFrame: number,
  framesPerSecond: number
): void {
  subclipBase.forEach(({ clip, name }) => {
    if (!clip) return

    const subclip = THREE.AnimationUtils.subclip(
      clip,
      name,
      startFrame,
      endFrame,
      framesPerSecond
    )

    addSubclipAnimations(subclip, animations)
  })
}

/**
 * It adds a subclip animation to an array of animations if it doesn't already exist in the array
 * @param subclipAnimation - The animation clip that you want to add to the animation array.
 * @param {THREE.AnimationClip[]} animationArray - This is the array of animations that you want to add
 * the subclip to.
 */
export function addSubclipAnimations(
  subclipAnimation: THREE.AnimationClip,
  animationArray: THREE.AnimationClip[]
): void {
  animationArray.find(animation => animation.name === subclipAnimation.name)
    ? ''
    : animationArray.push(subclipAnimation)
}

//################################
//#  Sample Animation Set
//################################

// {
//     id: 1,
//     blockingTime: {
//       forwardDuration: 1,
//       reverseDuration: 2,
//     },
//     modelSets: new Map([
// [
//   modelName,
//   {
//     actions: new Map([
//       [ScrollDirection.Down, () => {}],
//       [ScrollDirection.Up, () => {}],
//     ]),
//     movementSets: new Map([
//       [
//         Device.Mobile,
//         {
//           position: { data: new THREE.Vector3(0, 0, 0) },
//           rotation: { data: new THREE.Euler(0, 0, 0) },
//           scale: { data: new THREE.Vector3(1, 1, 1) },
//         },
//       ],
//       [
//         Device.Tablet,
//         {
//           position: { data: new THREE.Vector3(0, 0, 0) },
//           rotation: { data: new THREE.Euler(0, 0, 0) },
//           scale: { data: new THREE.Vector3(1, 1, 1) },
//         },
//       ],
//     ]),
//   },
// ],
//     ]),
//   },
