import * as THREE from 'three'
import gsap from 'gsap'
import disabledScrollListener from 'disable-scroll'
import {
  AllActions,
  AllRefs,
  AnimationSet,
  ScrollDirection,
  Device,
  MovementSet,
  MovementSets,
} from '../../interfaces/three.d'
import { delay } from './helper'
import { RefObject } from 'react'

export class ModelProvider {
  refList: AllRefs | null
  animationSet: AnimationSet[] | null
  actions: AllActions | null
  availableMediaQueries: Device[]
  isScrollDisabled: boolean = false
  private scrollDisableTimerId: NodeJS.Timeout | null = null

  private adjustScroll = (event: Event | null = null): void => {
    if (this.isScrollDisabled) event?.preventDefault()
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    window.scrollTo(scrollLeft, scrollTop)
  }

  constructor(
    allRefs: AllRefs | null = null,
    allActions: AllActions | null = null
  ) {
    this.refList = allRefs
    this.actions = allActions
    this.animationSet = null
    this.availableMediaQueries = [
      Device.Mobile,
      Device.Tablet,
      Device.Desktop_sm,
      Device.Laptop,
      Device.Desktop_md,
      Device.Desktop_lg,
    ]
  }

  public updateRefList(refList: AllRefs) {
    this.refList = refList
  }

  public updateActions(allActions: AllActions) {
    this.actions = allActions
  }

  public createAnimationSet() {
    if (this.refList === null) return null
    if (this.actions === null) return null

    const {
      planetScene,
      planet,
      planetLeft,
      planetRight,
      human,
      rocketAssemblyScene,
      rocketAssemblyPlanet,
      rocket,
      stars,
    } = this.refList

    const { ...actions } = this.actions

    this.animationSet = [
      {
        id: 0,
        blockingTime: {
          forwardDuration: 0,
          reverseDuration: 1,
        },
        modelSets: new Map([
          [
            planetScene,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(
                      0.5
                    )
                    actions.Armature_FerrisWheelAction?.play()

                    actions['Action.001']?.setEffectiveTimeScale(0.5)
                    actions['Action.001']?.play()

                    actions.Armature_BaloonAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_BaloonAction?.play()

                    actions[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(0.5)
                    actions['Armature_WindTurbine_01Action.001']?.play()

                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_TelescopeAction?.play()

                    actions.Action?.setEffectiveTimeScale(0.5)
                    actions.Action?.play()

                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_Clouds_LAction?.play()

                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_Clouds_RAction?.play()
                  },
                ],
                [
                  ScrollDirection.Up,
                  () => {
                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(
                      0.5
                    )
                    actions.Armature_FerrisWheelAction?.play()

                    actions['Action.001']?.setEffectiveTimeScale(0.5)
                    actions['Action.001']?.play()

                    actions.Armature_BaloonAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_BaloonAction?.play()

                    actions[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(0.5)
                    actions['Armature_WindTurbine_01Action.001']?.play()

                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_TelescopeAction?.play()

                    actions.Action?.setEffectiveTimeScale(0.5)
                    actions.Action?.play()

                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_Clouds_LAction?.play()

                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(0.5)
                    actions.Armature_Clouds_RAction?.play()
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, -35, 0) },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                    scale: { data: new THREE.Vector3(1.8, 1.8, 1.8) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, -55, -10) },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                    scale: { data: new THREE.Vector3(2.5, 2.5, 2.5) },
                  },
                ],
              ]),
            },
          ],
          [
            planet,
            {
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(-0.28, 0.2, -0.5) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, 5, 0) },
                    rotation: { data: new THREE.Euler(-0.28, 0.2, -0.5) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
              ]),
            },
          ],
        ]),
      },
      {
        id: 1,
        blockingTime: {
          forwardDuration: 1.1,
          reverseDuration: 2,
        },
        modelSets: new Map([
          [
            planetScene,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    actions.openPlanetAction_L?.stop()
                    actions.openPlanetAction_R?.stop()
                    actions['Armature_ArrowAction.001']?.play()
                    actions['Armature_ArrowAction.001']?.setEffectiveTimeScale(
                      1
                    )
                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(1)
                    actions['Action.001']?.setEffectiveTimeScale(1)
                    actions.Armature_BaloonAction?.setEffectiveTimeScale(1)
                    actions[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(1)
                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(1)
                    actions.Action?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(1)
                  },
                ],
                [
                  ScrollDirection.Up,
                  () => {
                    if (actions.openPlanetAction_L) {
                      actions.openPlanetAction_L.timeScale = -1
                      actions.openPlanetAction_L.paused = false
                    }
                    if (actions.openPlanetAction_R) {
                      actions.openPlanetAction_R.timeScale = -1
                      actions.openPlanetAction_R.paused = false
                    }
                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(1)
                    actions['Action.001']?.setEffectiveTimeScale(1)
                    actions.Armature_BaloonAction?.setEffectiveTimeScale(1)
                    actions[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(1)
                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(1)
                    actions.Action?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(1)
                    actions['Armature_ArrowAction.001']?.setEffectiveTimeScale(
                      1
                    )
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, -15, 0) },
                    rotation: { data: new THREE.Euler(0.1, 0.3, 0.2) },
                    scale: { data: new THREE.Vector3(1.2, 1.2, 1.2) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(-26, -1, 0) },
                    rotation: { data: new THREE.Euler(0.1, 0.3, 0.2) },
                    scale: { data: new THREE.Vector3(1.4, 1.4, 1.4) },
                  },
                ],
              ]),
            },
          ],
          [
            planet,
            {
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(0, -0, -Math.PI / 1.8) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(0, -0, -Math.PI / 1.8) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
              ]),
            },
          ],
          [
            human,
            {
              actions: new Map([
                [
                  ScrollDirection.Up,
                  () => {
                    actions.idle?.stop()
                    if (actions.reveal) {
                      actions.reveal.setLoop(THREE.LoopOnce, 1)
                      actions.reveal.clampWhenFinished = true
                      actions.reveal.timeScale = -1
                      actions.reveal.play()
                    }
                  },
                ],
              ]),
            },
          ],
        ]),
      },
      {
        id: 2,
        blockingTime: {
          forwardDuration: 2,
          reverseDuration: 2,
        },
        modelSets: new Map([
          [
            planetScene,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    if (actions.openPlanetAction_L) {
                      actions.openPlanetAction_L.paused = false
                      actions.openPlanetAction_L.timeScale = 1
                      actions.openPlanetAction_L.setLoop(THREE.LoopOnce, 1)
                      actions.openPlanetAction_L.clampWhenFinished = true
                      actions.openPlanetAction_L.play()
                    }
                    if (actions.openPlanetAction_R) {
                      actions.openPlanetAction_R.paused = false
                      actions.openPlanetAction_R.timeScale = 1
                      actions.openPlanetAction_R.setLoop(THREE.LoopOnce, 1)
                      actions.openPlanetAction_R.clampWhenFinished = true
                      actions.openPlanetAction_R.play()
                    }

                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(0)
                    // actions?.['Action.001']?.setEffectiveTimeScale(0)
                    actions.Armature_BaloonAction?.setEffectiveTimeScale(0)
                    actions?.[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(0)
                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(0)
                    actions.Action?.setEffectiveTimeScale(0)
                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(0)
                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(0)
                    actions['Armature_ArrowAction.001']?.setEffectiveTimeScale(
                      0
                    )
                  },
                ],
                [
                  ScrollDirection.Up,
                  () => {
                    if (actions.openPlanetAction_L) {
                      actions.openPlanetAction_L.paused = false
                      actions.openPlanetAction_L.timeScale = 1
                      actions.openPlanetAction_L.setLoop(THREE.LoopOnce, 1)
                      actions.openPlanetAction_L.clampWhenFinished = true
                      actions.openPlanetAction_L.play()
                    }
                    if (actions.openPlanetAction_R) {
                      actions.openPlanetAction_R.paused = false
                      actions.openPlanetAction_R.timeScale = 1
                      actions.openPlanetAction_R.setLoop(THREE.LoopOnce, 1)
                      actions.openPlanetAction_R.clampWhenFinished = true
                      actions.openPlanetAction_R.play()
                    }
                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(0)
                    actions?.['Action.001']?.setEffectiveTimeScale(0)
                    actions.Armature_BaloonAction?.setEffectiveTimeScale(0)
                    actions?.[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(0)
                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(0)
                    actions.Action?.setEffectiveTimeScale(0)
                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(0)
                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(0)
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, -20, 0) },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(30, -5, 0) },
                    rotation: { data: new THREE.Euler(0, -0.3, 0) },
                    scale: { data: new THREE.Vector3(1.4, 1.4, 1.4) },
                  },
                ],
              ]),
            },
          ],
          [
            planet,
            {
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(0, 0, -Math.PI / 2) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(0, 0.3, -Math.PI / 2) },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
              ]),
            },
          ],
          [
            human,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    actions.idle?.play()
                    actions.idleAndWave?.startAt(10).play()
                  },
                ],
              ]),
            },
          ],
        ]),
      },
      {
        id: 3,
        blockingTime: {
          forwardDuration: 2,
          reverseDuration: null,
        },
        modelSets: new Map([
          [
            planetScene,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    if (actions.openPlanetAction_L) {
                      actions.openPlanetAction_L.timeScale = -1
                      actions.openPlanetAction_L.paused = false
                    }
                    if (actions.openPlanetAction_R) {
                      actions.openPlanetAction_R.timeScale = -1
                      actions.openPlanetAction_R.paused = false
                    }

                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(1)
                    actions['Action.001']?.setEffectiveTimeScale(1)
                    actions.Armature_BaloonAction?.setEffectiveTimeScale(1)
                    actions[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(1)
                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(1)
                    actions.Action?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(1)
                  },
                ],
                [
                  ScrollDirection.Up,
                  () => {
                    if (actions.openPlanetAction_L) {
                      actions.openPlanetAction_L.timeScale = -1
                      actions.openPlanetAction_L.paused = false
                    }
                    if (actions.openPlanetAction_R) {
                      actions.openPlanetAction_R.timeScale = -1
                      actions.openPlanetAction_R.paused = false
                    }

                    actions.Armature_FerrisWheelAction?.setEffectiveTimeScale(1)
                    actions['Action.001']?.setEffectiveTimeScale(1)
                    actions.Armature_BaloonAction?.setEffectiveTimeScale(1)
                    actions[
                      'Armature_WindTurbine_01Action.001'
                    ]?.setEffectiveTimeScale(1)
                    actions.Armature_TelescopeAction?.setEffectiveTimeScale(1)
                    actions.Action?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_LAction?.setEffectiveTimeScale(1)
                    actions.Armature_Clouds_RAction?.setEffectiveTimeScale(1)
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, -15, 0) },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                    // scale is missing here since it gets handled within full page transition
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(-30, -5, 0) },
                    rotation: { data: new THREE.Euler(0, 0.2, 0) },
                    // scale is missing here since it gets handled within full page transition
                  },
                ],
              ]),
            },
          ],
          [
            planet,
            {
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: {
                      data: new THREE.Euler(
                        -Math.PI / 1.75,
                        Math.PI / 1.6,
                        Math.PI / 2.5
                      ),
                      duration: 1.5,
                    },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: {
                      data: new THREE.Euler(
                        -Math.PI / 1.75,
                        Math.PI / 1.6,
                        Math.PI / 2.5
                      ),
                      duration: 1.5,
                    },
                    scale: { data: new THREE.Vector3(1, 1, 1) },
                  },
                ],
              ]),
            },
          ],

          [
            rocketAssemblyScene,
            {
              actions: new Map([
                [
                  ScrollDirection.Up,
                  () => {
                    actions.Armature_MechanicalArmAction?.reset().stop()
                    delay(1.5).then(() => {
                      if (actions['Action.002']) {
                        actions['Action.002'].reset().stop()
                      }
                    })
                  },
                ],
              ]),
            },
          ],
          [
            stars,
            {
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, 75, 5) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, 75, 5) },
                  },
                ],
              ]),
            },
          ],
        ]),
      },
      {
        id: 4,
        blockingTime: {
          forwardDuration: null,
          reverseDuration: 3,
        },
        modelSets: new Map([
          [
            rocketAssemblyPlanet,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    if (actions.Armature_MechanicalArmAction) {
                      actions.Armature_MechanicalArmAction.paused = false
                      actions.Armature_MechanicalArmAction.timeScale = 1.5
                      actions.Armature_MechanicalArmAction.setLoop(
                        THREE.LoopOnce,
                        1
                      )
                      actions.Armature_MechanicalArmAction.clampWhenFinished =
                        true
                      actions.Armature_MechanicalArmAction.play()
                    }
                    if (actions['Action.002']) {
                      actions['Action.002'].paused = false
                      actions['Action.002'].timeScale = 1.5
                      actions['Action.002'].setLoop(THREE.LoopOnce, 1)
                      actions['Action.002'].clampWhenFinished = true
                      actions['Action.002'].play()
                    }

                    // Looped animations
                    actions.Armature_SkyAction?.play()
                    actions.Armature_Engineer_01Action?.play()
                    actions['Armature_Engineer_01.001Action']?.play()
                    actions['Armature_Engineer_01.002Action']?.play()
                    actions.Armature_CarAction?.play()
                    actions['Armature_ObservatoryAction.001']?.play()
                    actions.Armature_JoyPadAction?.play()
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: { data: new THREE.Vector3(0, 0, 0) },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                  },
                ],
              ]),
            },
          ],
          [
            rocket,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    delay(5).then(() => {
                      actions.rocketIdle?.setEffectiveTimeScale(0.3)
                      actions.rocketIdle?.play()
                    })
                  },
                ],
                [
                  ScrollDirection.Up,
                  () => {
                    actions.rocketStart?.stop()
                    delay(1).then(() => {
                      actions.rocketIdle?.play()
                    })
                  },
                ],
              ]),
              // This MS should be aligned with the position/rotation props passed to the rocket (!)
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: {
                      data: new THREE.Vector3(0.8, 2.2, 0.2),
                      duration: 1,
                    },
                    rotation: {
                      data: new THREE.Euler(0, -Math.PI * 0.125, 0),
                      duration: 2,
                    },
                    scale: { data: new THREE.Vector3(1, 1, 1), duration: 2 },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: {
                      data: new THREE.Vector3(0.8, 2.4, 0.2),
                      duration: 2,
                    },
                    rotation: {
                      data: new THREE.Euler(0, -Math.PI * 0.125, 0),
                      duration: 3,
                    },
                    scale: { data: new THREE.Vector3(1, 1, 1), duration: 3 },
                  },
                ],
              ]),
            },
          ],
          [
            stars,
            {
              actions: new Map([
                [
                  ScrollDirection.Up,
                  () => {
                    delay(1).then(() => {
                      actions['Armature_StarsAction.001']?.stop()
                    })
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: {
                      data: new THREE.Vector3(0, 75, 5),
                      duration: 2,
                    },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: {
                      data: new THREE.Vector3(0, 75, 5),
                      duration: 2,
                    },
                  },
                ],
              ]),
            },
          ],
        ]),
      },
      {
        id: 5,
        blockingTime: {
          forwardDuration: 4,
          reverseDuration: 2,
        },
        modelSets: new Map([
          [
            rocketAssemblyPlanet,
            {
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: {
                      data: new THREE.Vector3(8, -60, 0),
                      duration: 3,
                      delay: 0.5,
                    },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: {
                      data: new THREE.Vector3(8, -60, 0),
                      duration: 3,
                      delay: 0.5,
                    },
                    rotation: { data: new THREE.Euler(0, 0, 0) },
                  },
                ],
              ]),
            },
          ],
          [
            rocket,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    if (actions.rocketIdle) {
                      actions.rocketIdle.paused = true
                    }
                    if (actions.rocketStart) {
                      actions.rocketStart.paused = false
                      actions.rocketStart.timeScale = 1
                      actions.rocketStart.setLoop(THREE.LoopOnce, 1)
                      actions.rocketStart.clampWhenFinished = true
                      actions.rocketStart.play()
                    }
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: {
                      data: new THREE.Vector3(-11, 5, 0),
                      duration: 4,
                      delay: 0.5,
                    },
                    rotation: {
                      data: new THREE.Euler(0, 0, -(Math.PI / 4)),
                      duration: 4,
                      delay: 0.5,
                    },
                    scale: {
                      data: new THREE.Vector3(1.1, 1.1, 1.1),
                      duration: 3,
                    },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: {
                      data: new THREE.Vector3(-2, 10, 0),
                      duration: 4,
                      delay: 1,
                    },
                    rotation: {
                      data: new THREE.Euler(0, 0, -(Math.PI / 4)),
                      duration: 4,
                      delay: 0.5,
                    },
                    scale: {
                      data: new THREE.Vector3(1.3, 1.3, 1.3),
                      duration: 3,
                    },
                  },
                ],
              ]),
            },
          ],
          [
            stars,
            {
              actions: new Map([
                [
                  ScrollDirection.Down,
                  () => {
                    delay(1).then(() => {
                      actions['Armature_StarsAction.001']?.play()
                      gsap.fromTo(
                        actions['Armature_StarsAction.001'],
                        {
                          timeScale: 1,
                        },
                        {
                          duration: 5,
                          timeScale: 0.15,
                          ease: 'none',
                        }
                      )
                    })
                  },
                ],
              ]),
              movementSets: new Map([
                [
                  Device.Mobile,
                  {
                    position: {
                      data: new THREE.Vector3(0, -75, 0),
                      duration: 3,
                      delay: 1,
                    },
                  },
                ],
                [
                  Device.Tablet,
                  {
                    position: {
                      data: new THREE.Vector3(0, -75, 0),
                      duration: 3,
                      delay: 1,
                    },
                  },
                ],
              ]),
            },
          ],
        ]),
      },
    ]
  }

  /**
   * It takes in a canvas reference, an animation index, a scroll direction, and a current media query,
   * and  plays the animation that corresponds to the animation index, scroll direction, and
   * current media query
   * @param canvasRef - React.RefObject<HTMLCanvasElement>
   * @param {number} animationIndex - The index of the animation set to play.
   * @param {ScrollDirection} scrollDirection - ScrollDirection.Down | ScrollDirection.Up
   * @param {Device} currentMediaQuery - Device
   * @returns the closest media query to the current media query.
   */
  public playAnimation(
    canvasRef: React.RefObject<HTMLCanvasElement>,
    animationIndex: number,
    scrollDirection: ScrollDirection,
    currentMediaQuery: Device
  ): void {
    if (this.animationSet === null) return
    const currentAnimationSet = this.animationSet.find(
      animationSet => animationSet.id === animationIndex
    )
    if (!currentAnimationSet) return

    // disable Scroll
    const disableScrollDuration =
      scrollDirection === ScrollDirection.Down
        ? currentAnimationSet.blockingTime.forwardDuration
        : currentAnimationSet.blockingTime.reverseDuration

    this.disableScroll(disableScrollDuration, canvasRef)

    currentAnimationSet.modelSets.forEach((modelSet, model) => {
      // move models
      const movementSets = modelSet.movementSets
      if (movementSets) {
        if (!movementSets.has(currentMediaQuery)) {
          const closestMediaQuery = this.getClosestMediaQuery(
            this.availableMediaQueries,
            currentMediaQuery,
            movementSets
          )
          currentMediaQuery = closestMediaQuery
        }
        const movementSet = movementSets.get(currentMediaQuery)
        if (!movementSet) return
        this.moveThreeObject(movementSet, model)
      }

      // play animations
      if (!modelSet.actions) return

      if (modelSet.actions.get(scrollDirection)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        modelSet.actions.get(scrollDirection)!()
      }
    })
  }

  /**
   * It resets the position, rotation, and scale of all the objects in the scene
   * @param {Device} currentMediaQuery - Device
   * @returns The return value is a function that is called when the component is unmounted.
   */
  public resetAnimations(currentMediaQuery: Device): void {
    // Reset movements
    if (this.refList === null) return

    const {
      planetScene,
      planet,
      planetLeft,
      planetRight,
      human,
      rocketAssemblyScene,
      rocketAssemblyPlanet,
      rocket,
      stars,
    } = this.refList

    if (currentMediaQuery === Device.Mobile) {
      planetScene.current?.position.set(0, -15, 0)
      planetScene.current?.rotation.set(0, 0, 0)
      planetScene.current?.scale.set(1, 1, 1)

      planet.current?.position.set(0, 0, 0)
      planet.current?.rotation.set(0, 0, 0)
      planet.current?.scale.set(1, 1, 1)

      planetLeft.current?.position.set(0, 0, 0)
      //   planetLeft.current?.rotation.set(0, 0, 0)
      //   planetLeft.current?.scale.set(0, 0, 0)

      //   planetRight.current?.position.set(0, 0, 0)
      planetRight.current?.rotation.set(0, 0, 0)
      //   planetRight.current?.scale.set(0, 0, 0)

      human.current?.position.set(0, -2, 0)
      //   human.current?.rotation.set(0, 0, 0)
      human.current?.scale.set(1, 1, 1)

      rocketAssemblyScene.current?.position.set(8, -30, 0)
      rocketAssemblyScene.current?.rotation.set(0, 0, 0)
      rocketAssemblyScene.current?.scale.set(0, 0, 0)

      rocketAssemblyPlanet.current?.position.set(0, 0, 0)
      rocketAssemblyPlanet.current?.rotation.set(0, 0, 0)
      //   rocketAssemblyPlanet.current?.scale.set(0, 0, 0)

      rocket.current?.position.set(0.8, 2.2, 0.2)
      rocket.current?.rotation.set(0, -Math.PI * 0.125, 0)
      rocket.current?.scale.set(1, 1, 1)

      stars.current?.position.set(0, 50, 5)
      //   stars.current?.rotation.set(0, 0, 0)
      //   stars.current?.scale.set(0, 0, 0)
    }

    if (
      currentMediaQuery === Device.Tablet ||
      currentMediaQuery === Device.Desktop_sm ||
      currentMediaQuery === Device.Desktop_md ||
      currentMediaQuery === Device.Desktop_lg
    ) {
      planetScene.current?.position.set(20, 0, 0)
      planetScene.current?.rotation.set(0, 0, 0)
      planetScene.current?.scale.set(1, 1, 1)

      planet.current?.position.set(0, 0, 0)
      planet.current?.rotation.set(0, 0, 0)
      planet.current?.scale.set(1, 1, 1)

      planetLeft.current?.position.set(0, 0, 0)
      //   planetLeft.current?.rotation.set(0, 0, 0)
      //   planetLeft.current?.scale.set(0, 0, 0)

      //   planetRight.current?.position.set(0, 0, 0)
      planetRight.current?.rotation.set(0, 0, 0)
      //   planetRight.current?.scale.set(0, 0, 0)

      human.current?.position.set(0, -2, 0)
      human.current?.rotation.set(0, 0, 0)
      human.current?.scale.set(1, 1, 1)

      rocketAssemblyScene.current?.position.set(8, -30, 0)
      rocketAssemblyScene.current?.rotation.set(0, 0, 0)
      rocketAssemblyScene.current?.scale.set(0, 0, 0)

      rocketAssemblyPlanet.current?.position.set(0, 0, 0)
      rocketAssemblyPlanet.current?.rotation.set(0, 0, 0)
      //   rocketAssemblyPlanet.current?.scale.set(0, 0, 0)

      rocket.current?.position.set(-1.6, -10.3, 0.2)
      rocket.current?.rotation.set(0, -Math.PI * 0.125, 0)
      rocket.current?.scale.set(1, 1, 1)

      stars.current?.position.set(0, 75, 5)
      //   stars.current?.rotation.set(0, 0, 0)
      //   stars.current?.scale.set(0, 0, 0)
    }
    // Reset actions
    if (this.actions === null) return
    this.actions.openPlanetAction_L?.stop()
    this.actions.openPlanetAction_R?.stop()
  }

  /**
   * It disables the scroll of the window for a given duration
   * @param {number | null} duration - The duration in seconds that the scrolling should be disabled.
   * @param ref - RefObject<HTMLCanvasElement>
   * @param {string} [calledByElement] - This is a string that is used to identify the element that
   * called the function. This is used for debugging purposes.
   * @returns The return type is void.
   */
  public disableScroll(
    duration: number | null,
    ref: RefObject<HTMLCanvasElement>,
    calledByElement?: string
  ): void {
    const disableWindowScroll = (): void => {
      if (ref.current) {
        ref.current.style.overflow = 'hidden'
      }
      disabledScrollListener.on()
      window.addEventListener('scroll', this.adjustScroll, { passive: false })
    }

    if (duration) {
      if (this.isScrollDisabled) {
        this.adjustScroll()
        /*    console.error(
          'The scrolling is already disabled. A second call should never occur.'
        ) */
        return
      }

      disableWindowScroll()
      this.isScrollDisabled = true

      this.scrollDisableTimerId = setTimeout(() => {
        this.isScrollDisabled = false
        this.enableWindowScroll(ref)
        this.scrollDisableTimerId = null
      }, duration * 1000)
    }
  }

  /**
   * "If the scrollDisableTimerId is set, clear the timeout and enable window scrolling."
   * @param ref - RefObject<HTMLCanvasElement>
   */
  public forceEnableWindowScroll(ref: RefObject<HTMLCanvasElement>): void {
    if (this.scrollDisableTimerId) clearTimeout(this.scrollDisableTimerId)
    this.enableWindowScroll(ref)
  }

  /**
   * It removes the scroll listener and sets the canvas overflow to auto
   * @param ref - RefObject<HTMLCanvasElement>
   */
  enableWindowScroll(ref: RefObject<HTMLCanvasElement>): void {
    if (ref.current) {
      ref.current.style.overflow = 'auto'
    }
    disabledScrollListener.off()
    window.removeEventListener('scroll', this.adjustScroll)
  }

  /**
   * > If the current media query is not available, return the next available media query
   * @param {Device[]} availableMediaQueries - An array of all the media queries that are available to
   * the user.
   * @param {Device} currentMediaQuery - The current media query that the user is on.
   * @param {MovementSets} movementSets - A Map of all the media queries and their corresponding
   * movement sets.
   * @returns The closest media query to the current media query.
   */
  getClosestMediaQuery(
    availableMediaQueries: Device[],
    currentMediaQuery: Device,
    movementSets: MovementSets
  ): Device {
    const movementSet = movementSets.get(currentMediaQuery)
    if (!movementSet) return currentMediaQuery
    if (availableMediaQueries.indexOf(currentMediaQuery) === -1)
      return currentMediaQuery

    const nextAvailableMediaQueryIndex =
      availableMediaQueries.indexOf(currentMediaQuery) - 1
    const nextAvailableMediaQuery =
      availableMediaQueries[nextAvailableMediaQueryIndex]
    return this.getClosestMediaQuery(
      availableMediaQueries,
      nextAvailableMediaQuery,
      movementSets
    )
  }

  /**
   * It takes a `MovementSet` and a `React.RefObject<THREE.Group>` and moves the object in the ref
   * based on the `MovementSet`
   * @param {MovementSet} movementSet - MovementSet
   * @param targetElement - React.RefObject<THREE.Group>
   * @returns A function that takes in a MovementSet and a targetElement.
   */
  moveThreeObject(
    movementSet: MovementSet,
    targetElement: React.RefObject<THREE.Group>
  ): void {
    // early out if a falsy ref is provided
    if (!targetElement.current) return

    const { position, rotation, scale } = movementSet

    if (position) {
      gsap.to(targetElement.current.position, {
        duration: position.duration || 1,
        delay: position.delay || 0,
        x: position.data.x,
        y: position.data.y,
        z: position.data.z,
      })
    }

    if (rotation) {
      gsap.to(targetElement.current.rotation, {
        duration: rotation.duration || 1,
        delay: rotation.delay || 0,
        x: rotation.data.x,
        y: rotation.data.y,
        z: rotation.data.z,
      })
    }

    if (scale) {
      gsap.to(targetElement.current.scale, {
        duration: scale.duration || 1,
        delay: scale.delay || 0,
        x: scale.data.x,
        y: scale.data.y,
        z: scale.data.z,
      })
    }
  }
}
