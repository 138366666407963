import styled, { useTheme } from 'styled-components/macro'
import React, { useEffect, useMemo, useState } from 'react'

import Typography from '../common/Typography'

import CupIcon from '../../assets/images/home/cup.svg'
import ThreeIcon from '../../assets/images/home/3.svg'
import FaceIcon from '../../assets/images/home/face.svg'
import PeaceIcon from '../../assets/images/home/peace.svg'
import { useTranslation } from 'react-i18next'

interface MessageProps {
  clickCount: number
}

export default function EngagementMessage({ clickCount }: MessageProps) {
  const [isVisible, setVisible] = useState<boolean>(true)
  const { t } = useTranslation()
  const theme = useTheme()
  const delay = 4000
  const totalMessageCount = 3
  const messages = [
    {
      heading: t('homepage.engagement.first.title'),
      text: t('homepage.engagement.first.desc'),
      icon: CupIcon,
      color: theme.palette.company.prlOrange,
    },
    {
      heading: t('homepage.engagement.second.title'),
      text: t('homepage.engagement.second.desc'),
      icon: PeaceIcon,
      color: theme.palette.company.prlBlue,
    },
    {
      heading: t('homepage.engagement.third.title'),
      text: t('homepage.engagement.third.desc'),
      icon: ThreeIcon,
      color: theme.palette.company.prlRed,
    },
  ]

  const { heading, text, icon, color } = useMemo(() => {
    switch (clickCount % totalMessageCount) {
      case 0:
        return messages[2]
      case 1:
        return messages[0]
      case 2:
        return messages[1]
      default:
        return messages[0]
    }
  }, [clickCount])

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
    }, delay)
    return () => {
      clearTimeout(timer)
    }
  }, [delay])

  return (
    <StyledMessageExpireContainer isVisible={isVisible}>
      <StyledMessageContainer>
        <StyledMessageWrapper>
          <StyledMessageIconContainer bgColor={color}>
            <StyledMessageIcon src={icon} alt="Icon" />
          </StyledMessageIconContainer>
          <StyledMessageTextWrapper>
            <StyledMessageText type="copy">
              <StyledMessageHeading color={color}>
                {heading}
              </StyledMessageHeading>
              {text}
            </StyledMessageText>
          </StyledMessageTextWrapper>
        </StyledMessageWrapper>
      </StyledMessageContainer>
    </StyledMessageExpireContainer>
  )
}
const StyledMessageExpireContainer = styled.div<{
  isVisible: boolean
}>`
  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(100vh)' : 'translateY(200vh)'};
  transition: 0.75s all ease-in-out;
  transition-property: opacity, transform;
  ${({ theme }) => theme.breakpoints.queries.sm} {
    transform: ${({ isVisible }) =>
      isVisible ? 'translateY(100vh)' : 'translateY(-100vh)'};
  }
`

const StyledMessageContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 7rem;
  transform: translate(-50%);
  max-width: 45rem;
  width: 90%;
  background-color: transparent;
  border-radius: 0rem;
  text-align: left;

  ${({ theme }) => theme.breakpoints.queries.sm} {
    bottom: 3.5rem;
  }
`

const StyledMessageIconContainer = styled.div<{ bgColor: string }>`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${({ bgColor }) => bgColor};
  position: relative;
`

const StyledMessageIcon = styled.img`
  position: absolute;
  height: 70%;
  width: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const StyledMessageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-items: center;
  background-color: ${({ theme }) => theme.palette.global.white};
  padding: 1.1rem 1.4rem 1.1rem;
  border-radius: 3.8rem;
  box-shadow: 0 0.1rem 2rem 0.5rem rgba(116, 134, 169, 0.2);
`
const StyledMessageTextWrapper = styled.div`
  width: 85%;
  padding: 0 0 0 1rem;
`

const StyledMessageText = styled(Typography)`
  display: inline;
  margin: 0;
  color: ${({ theme }) => theme.palette.global.accent};
`

const StyledMessageHeading = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 700;
  margin: 0 1ex 0 0;
`
