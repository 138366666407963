import React from 'react'
import styled from 'styled-components/macro'

interface Props {
  color?: string
  isFirstAnimation?: boolean
}

ArrowDownHomeTextLink.defaultProps = {
  isFirstAnimation: false,
}

export default function ArrowDownHomeTextLink({
  color,
  isFirstAnimation,
}: Props): JSX.Element {
  return (
    <StyledArrowDown
      role="svg"
      width="13"
      height="21"
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isFirstAnimation={isFirstAnimation}
    >
      <title>Discover More Arrow</title>
      <path d="M6.43359 0V18.794" stroke={color} strokeWidth="2" />
      <path
        d="M1 13.362L6.43311 18.795L11.866 13.362"
        stroke={color}
        strokeWidth="2"
      />
    </StyledArrowDown>
  )
}

const StyledArrowDown = styled.svg<{
  isFirstAnimation?: boolean
}>`
  display: ${({ isFirstAnimation }) => (isFirstAnimation ? 'block' : 'none')};
  margin: 1rem auto;
  position: static;
  bottom: 2rem;
  left: 0;
  right: 0;
  animation-duration: 1s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  transition: opacity 0.3s ease-in;
  animation-name: 'Arrow-Down-Animation';
`
